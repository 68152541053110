/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { Link } from 'gatsby'

export const ActionsLink = ({
  title,
  url,
  onClick,
}: {
  title: string
  url?: string
  onClick?: () => void
}) => {
  const Component = onClick ? 'button' : Link

  const props: any = {}

  if (onClick) {
    props['onClick'] = onClick
  } else if (url) {
    props['href'] = url
  }
  return (
    <Component
      sx={{
        all: 'unset',
        textDecoration: 'none',
        lineHeight: '1px',
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '16px',
        color: '#2b2b2b',
        margin: 'auto',
        cursor: 'pointer',
        mr: [26],
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '&:hover': {
          opacity: '80%',
          transition: 'all .3s ease',
        },
      }}
      {...props}
    >
      {/* <Pencil color="#2b2b2b" width="24px" height="24px" /> */}
      <Text sx={{ textTransform: 'uppercase' }}>{title}</Text>
    </Component>
  )
}

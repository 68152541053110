/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Router } from '@reach/router'
import urls from '~/common/urls'
import PrivateRoute from '~/components/private-route'
import { ACCOUNT_ROUTES } from '~/components/account-layout/constants/routeConfig'

export const AccountRouter = () => {
  const BasePath = urls.account.account
  return (
    <Router basepath={urls.account.account}>
      {ACCOUNT_ROUTES.map(route => (
        <PrivateRoute
          key={route.path}
          path={route.path.replace(BasePath, '')}
          component={route.component}
        />
      ))}
    </Router>
  )
}

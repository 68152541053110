/** @jsx jsx */
import { Container, jsx, Box, Flex, Text, Grid } from 'theme-ui'
import { FC, useEffect, useRef, useState } from 'react'
import { Button } from '~/components/button'
import urls from '~/common/urls'
import { space } from '~/gatsby-plugin-theme-ui'
import { useCustomerContext } from '~/context/customer-context'
import ShoppingCart from '~/components/icons/shoppingCart'
import { Link, navigate } from 'gatsby'
import ShareLinkModal from '../shareLinkModal'
import NotificationModal from '../notificationModal'
import { ArrowSelector } from '~/components/icons/arrowSelectos'
import { axiosRequests } from '~/utils/axios'
import { ActionsLink } from '~/components/account-layout/components/actionLinks'
import { DashboardProductCard } from '~/components/account-layout/components/productCard'

type Props = {}

const AccountPortfoliosDetail: FC<Props> = ({ id }) => {
  const { customer } = useCustomerContext()
  const [loading, setLoading] = useState<boolean>(true)
  const [shareUrl, setshareUrl] = useState('')
  const [isSelectOpen, setisSelectOpen] = useState(false)
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [toastlIsOpen2, settoastlIsOpen2] = useState(false)
  const [regid, setRegid] = useState('')
  const [sessionid, setSessionid] = useState('')
  const [listName, setListName] = useState('')
  const [prodsArray, setProdsArray] = useState([])
  const [listsNames, setListsNames] = useState([])
  const [hasProducts, setHasProducts] = useState(false)
  const [wishlistName, setwishlistName] = useState('')
  const [toastText, settoastText] = useState('')
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const AxiosFunctions = axiosRequests()

  const closeToast = () => {
    settoastlIsOpen(false)
  }
  const menuRef = useRef()
  const handleMenuToggle = () => {
    setisSelectOpen(!isSelectOpen)
  }
  const handleOutsideClick = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setisSelectOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const genRegid = async () => {
    await AxiosFunctions.genRegId(customer?.email)
      .then(function (response) {
        setRegid(response.data.regid)
        setSessionid(response.data.sessionid)
      })
      .catch(function () {
        // console.log(error);
      })
  }
  useEffect(() => {
    genRegid()
  }, [])

  const getSwymlists = async () => {
    await AxiosFunctions.getSwymlists(sessionid, regid).then(function (response) {
      setListsNames(response.data)
    })
  }

  useEffect(() => {
    if (regid !== '' && sessionid !== '') {
      getSwymlists()
    }
  }, [regid, sessionid, customer])

  const removeWishlist = async () => {
    await AxiosFunctions.removeWishlist(sessionid, regid, id)
      .then(function (response) {
        // setwishlistName(listName)
        settoastText('Deleted wishlist')
        setisSuccesful(true)
        settoastlIsOpen2(true)
        setTimeout(() => {
          settoastlIsOpen2(false)
          return navigate(urls.account.accountPortfolios)
        }, 2200)
      })
      .catch(function () {
        // setwishlistName(listName)
        settoastText('Could not delete wishlist')
        setisSuccesful(false)
        settoastlIsOpen2(true)
        setTimeout(() => {
          settoastlIsOpen2(false)
        }, 2200)
      })
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const getListProducts = async () => {
    await AxiosFunctions.getListProducts(sessionid, regid, id).then(function (response) {
      setListName(response.data.list.lname)
      const arrayOfProducts = response.data.items
      setProdsArray(arrayOfProducts)
      if (response.data.items.length > 0) {
        setHasProducts(true)
      } else {
        setHasProducts(false)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (sessionid !== '' && regid !== '') {
      getListProducts()
    }
  }, [customer, regid, sessionid, id])

  function createShareUrl() {
    const onlyIdsArray: number[] = []
    const onlyVIdsArray: number[] = []
    prodsArray.map(product => {
      const onlyId = product.empi.toString()
      const onlyVId = product.epi.toString()
      onlyIdsArray.push(onlyId)
      onlyVIdsArray.push(onlyVId)
      return ''
    })

    const data = {
      productsIds: onlyIdsArray,
      variantIds: onlyVIdsArray,
      listName: listName,
      userName: customer?.displayName,
    }
    const encodedData = btoa(JSON.stringify(data))

    const hostName =
      window.location.hostname + (window.location.hostname === 'localhost' ? ':8002' : '')
    const shareUrl = `${hostName}${urls.account.accountPortfoliosShared}?data=${encodedData}`
    setshareUrl(shareUrl)
    settoastlIsOpen(true)
  }

  return (
    <Box>
      <Container variant="container">
        <Box sx={{ display: 'relative' }}>
          <Link
            sx={{
              textDecoration: 'none',
              color: '#808080',
              '&:hover': { textDecoration: 'underline' },
            }}
            to={urls.account.accountPortfolios}
          >
            <Text sx={{ color: '#808080', fontSize: '14px' }}>Back to Projects</Text>
          </Link>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Box sx={{ display: 'inline-flex', cursor: 'pointer' }} onClick={handleMenuToggle}>
              <h1
                sx={{
                  fontFamily: 'Cormorant Garamond, Times New Roman, serif',
                  fontWeight: 400,
                  fontSize: '33.18px',
                  mt: [0],
                  lineHeight: '48.44px',
                  color: '#2b2b2b',
                  mb: [0],
                  textTransform: 'uppercase',
                  // marginRight: ['10px', '20px'],
                }}
              >
                {listName === 'default' ? 'my favorites' : listName}
              </h1>
              {listsNames.length > 1 && <ArrowSelector />}
            </Box>
            {isSelectOpen && (
              <div
                className="flex flex-col"
                ref={menuRef}
                style={{
                  alignItems: 'flex-start',
                  position: 'absolute',
                  zIndex: 1,
                  width: 'auto',
                  minWidth: '150px',
                  backgroundColor: 'white',
                  textAlign: 'initial',
                  padding: '24px 16px',
                  boxShadow: '0px 4px 11.7px rgba(0, 0, 0, 0.11)',
                }}
              >
                <ul
                  className="flex"
                  style={{
                    listStyle: 'none',
                    padding: '0px',
                    margin: 0,
                    lineHeight: '2.5em',
                  }}
                >
                  {listsNames.map((list, index) => {
                    if (list.lid === id) {
                      return ''
                    }
                    return (
                      <Link
                        sx={{
                          textDecoration: 'none',
                        }}
                        to={`${urls.account.accountPortfolios}/${list.lid}`}
                        onClick={handleMenuToggle}
                      >
                        <li>
                          <div
                            style={{
                              color: '#2b2b2b',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '6px',
                            }}
                          >
                            <span
                              sx={{
                                fontFamily: 'Cormorant Garamond, Times New Roman, serif',
                                fontSize: '24.19px',
                                textTransform: 'uppercase',
                                '&:hover': {
                                  background: 'lightGray',
                                },
                              }}
                            >
                              {list.lname === 'default' ? 'my favorites' : list.lname}
                            </span>
                          </div>
                        </li>
                      </Link>
                    )
                  })}
                </ul>
              </div>
            )}
          </Box>
          <Flex>
            {listName !== 'default' ? (
              <ActionsLink title="Rename" url={`${urls.account.accountPortfoliosEdit}/:${id}`} />
            ) : null}

            <ActionsLink title="Share" onClick={() => createShareUrl()} />

            {listName !== 'default' && (
              <ActionsLink title="Delete" onClick={() => removeWishlist()} />
            )}
          </Flex>
        </Box>
        <Box sx={{ mb: [10], textAlign: 'right' }}>
          <Text sx={{ fontSize: '16px', fontWeight: '400' }}>
            {prodsArray.length} item{prodsArray.length > 1 ? 's' : ''}
          </Text>
        </Box>
        {!hasProducts && !loading && (
          <Box sx={{ display: 'grid', placeItems: 'center', gap: '10px', mt: [63] }}>
            <ShoppingCart />
            <span>Project empty</span>
            <Button
              label="Start Shopping"
              as="link"
              to={urls.collections.newArrivals}
              variant="primary"
              sxProps={{
                textDecoration: 'none',
              }}
            />
          </Box>
        )}
        <Grid columns={[2, null, null, 3]} sx={{}} gap={space.gridGap3}>
          {!loading &&
            prodsArray.map((list, index) => {
              return <DashboardProductCard key={index} listName={listName} list={list} />
            })}
        </Grid>
        {toastlIsOpen && (
          <Box>
            <ShareLinkModal
              isOpen={toastlIsOpen}
              shareLink={shareUrl}
              isMobile={false}
              onRequestClose={closeToast}
            />
          </Box>
        )}
        {toastlIsOpen2 && (
          <Box>
            <NotificationModal
              toastText={toastText}
              wishlistName={wishlistName}
              isFromModal={true}
              isSuccesful={isSuccesful}
              isMobile={false}
              isOpen={toastlIsOpen2}
              onRequestClose={closeToast}
            />
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default AccountPortfoliosDetail

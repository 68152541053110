/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { FC, useState } from 'react'
import { object, string } from 'yup'
import FormField from '~/components/form-field'
import useFormSubmission from '~/hooks/use-form-submission'
import Form from '~/components/form'
import FormReadOnly from '~/components/form-read-only'
import { useCustomerContext } from '~/context/customer-context'
import { graphql, useStaticQuery } from 'gatsby'
import { SiteMetaDataType } from '~/@types/models'
import NotificationModal from '~/templates/notificationModal'

type FormValues = {
  NewPassword: string
  OldPassword: string
}

const schema = object().shape({
  NewPassword: string().required('New password is required'),
  OldPassword: string().required('Old password is required'),
})

type QueryResult = {
  site: {
    siteMetadata: SiteMetaDataType
  }
}

type Props = { initialValues?: FormValues }

const ChangePasswordForm: FC<Props> = () => {
  const { customer } = useCustomerContext()
  const { updateCustomer } = useCustomerContext()
  const { validatePassword } = useCustomerContext()
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const [toastText, settoastText] = useState('')
  const [isCancel, setIsCancel] = useState(false)
  const [wishlistName, setwishlistName] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const openToast = async (copyBoolean: boolean) => {
    settoastlIsOpen(true)
  }

  const closeToast = () => {
    settoastlIsOpen(false)
  }

  const OpenEye = (props: TSvgProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M11.5 18c4 0 7.46-2.22 9.24-5.5C18.96 9.22 15.5 7 11.5 7s-7.46 2.22-9.24 5.5C4.04 15.78 7.5 18 11.5 18m0-12c4.56 0 8.5 2.65 10.36 6.5C20 16.35 16.06 19 11.5 19S3 16.35 1.14 12.5C3 8.65 6.94 6 11.5 6m0 2C14 8 16 10 16 12.5S14 17 11.5 17S7 15 7 12.5S9 8 11.5 8m0 1A3.5 3.5 0 0 0 8 12.5a3.5 3.5 0 0 0 3.5 3.5a3.5 3.5 0 0 0 3.5-3.5A3.5 3.5 0 0 0 11.5 9"
      />
    </svg>
  )

  const CloseEye = (props: TSvgProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M2.54 4.71L3.25 4L20 20.75l-.71.71l-3.34-3.35c-1.37.57-2.87.89-4.45.89c-4.56 0-8.5-2.65-10.36-6.5c.97-2 2.49-3.67 4.36-4.82zM11.5 18c1.29 0 2.53-.23 3.67-.66l-1.12-1.13c-.73.5-1.6.79-2.55.79C9 17 7 15 7 12.5c0-.95.29-1.82.79-2.55L6.24 8.41a10.64 10.64 0 0 0-3.98 4.09C4.04 15.78 7.5 18 11.5 18m9.24-5.5C18.96 9.22 15.5 7 11.5 7c-1.15 0-2.27.19-3.31.53l-.78-.78C8.68 6.26 10.06 6 11.5 6c4.56 0 8.5 2.65 10.36 6.5a11.47 11.47 0 0 1-4.07 4.63l-.72-.73c1.53-.96 2.8-2.3 3.67-3.9M11.5 8C14 8 16 10 16 12.5c0 .82-.22 1.58-.6 2.24l-.74-.74c.22-.46.34-.96.34-1.5A3.5 3.5 0 0 0 11.5 9c-.54 0-1.04.12-1.5.34l-.74-.74c.66-.38 1.42-.6 2.24-.6M8 12.5a3.5 3.5 0 0 0 3.5 3.5c.67 0 1.29-.19 1.82-.5L8.5 10.68c-.31.53-.5 1.15-.5 1.82"
      />
    </svg>
  )

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const siteUrl = query.site.siteMetadata.siteUrl

  const submitCallback = async (values: FormValues) => {
    // console.log(customer?.email);
    if (isCancel) {
      setIsCancel(false)
      return
    }
    if (customer) {
      const email = customer.email
      try {
        await validatePassword({ email: email, password: values.OldPassword }, siteUrl)
        // console.log('contraseña anterior correcta')
        await updateCustomer({
          password: values.NewPassword,
        })
        await settoastText('Password changed successfully')
        await setisSuccesful(true)
        await settoastlIsOpen(true)
        await setTimeout(() => {
          settoastlIsOpen(false)
        }, 3200)
      } catch (e) {
        await settoastText('Password was not changed')
        await setisSuccesful(false)
        await settoastlIsOpen(true)
        await setTimeout(() => {
          // settoastlIsOpen(false)
        }, 3200)
        // console.log(e)
      }
    }
  }

  const [isRead, setIsRead] = useState(true) // Initialize with the default
  const editProfile = () => {
    setIsRead(prevIsRead => !prevIsRead)
  }

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(submitCallback)

  return (
    <FormReadOnly
      formProps={{
        onSubmit,
        validationSchema: !isCancel ? schema : undefined,
        initialValues: {
          OldPassword: '',
          NewPassword: '',
        },
      }}
      // buttonLabel="Change Password"
      isLoading={isLoading}
      serverError={serverError}
      setIsRead={setIsRead}
      setIsCancel={setIsCancel}
      isCancel={isCancel}
    >
      <FormField
        sx={{ width: '50%' }}
        name="OldPassword"
        label="OLD PASSWORD *"
        data-cy="password"
        type={showPassword ? 'text' : 'password'}
        rightIcon={
          showPassword ? (
            <CloseEye
              onClick={() => setShowPassword(false)}
              style={{ width: '24px', height: '24px', right: '50%' }}
            />
          ) : (
            <OpenEye
              onClick={() => setShowPassword(true)}
              style={{ width: '24px', height: '24px', right: '50%' }}
            />
          )
        }
      />
      <FormField
        sx={{ width: '50%' }}
        name="NewPassword"
        label="NEW PASSWORD *"
        data-cy="password"
        type={showPassword ? 'text' : 'password'}
        rightIcon={
          showPassword ? (
            <CloseEye
              onClick={() => setShowPassword(false)}
              style={{ width: '24px', height: '24px', right: '50%' }}
            />
          ) : (
            <OpenEye
              onClick={() => setShowPassword(true)}
              style={{ width: '24px', height: '24px', right: '50%' }}
            />
          )
        }
      />
      {toastlIsOpen && (
        <Box>
          <NotificationModal
            toastText={toastText}
            wishlistName={wishlistName}
            isFromModal={true}
            isSuccesful={isSuccesful}
            isMobile={false}
            isOpen={toastlIsOpen}
            onRequestClose={closeToast}
          />
        </Box>
      )}
    </FormReadOnly>
  )
}

export default ChangePasswordForm

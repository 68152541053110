import React, { createContext, FC, useContext } from 'react'
import { ProductItemComponentType, ProductVariantTypeV2 } from '~/@types/models'

type CustomerContextType = {
  product: ProductItemComponentType
  variant: ProductVariantTypeV2
}

const ProductVariantContext = createContext<CustomerContextType>({
  variant: null,
  product: null,
})

type Props = {
  product: ProductItemComponentType
  variant: ProductVariantTypeV2
}

const ProductVariantProvider: FC<Props> = ({ children, product, variant }) => {
  return (
    <ProductVariantContext.Provider
      value={{
        variant,
        product,
      }}
    >
      {children}
    </ProductVariantContext.Provider>
  )
}

const useProductVariantContext = () => {
  const context = useContext(ProductVariantContext)

  if (context === undefined) {
    throw new Error('useProductVariantContext must be used within a ProductVariantProvider')
  }

  return context
}

export { ProductVariantProvider, useProductVariantContext }

/** @jsx jsx **/
import { Box, jsx, Flex } from 'theme-ui'
import { FC, ChangeEvent } from 'react'
import { navigate, Link as GatsbyLink } from 'gatsby'
import RouteLinkMobile from '~/components/account-layout/route-link-mobile'
import urls from '~/common/urls'
import { ACCOUNT_ROUTES_MENU } from './constants/routeConfig'

type Props = {
  pathname: string
}

const isCurrentLink = (pathname: string, base: string) => {
  return String(pathname).startsWith(base)
}

const AccountAsideMobileScroll: FC<Props> = ({ pathname }) => {
  const linkLabels = ACCOUNT_ROUTES_MENU.map(item => item.label)
  const newLinkLabels = [...linkLabels, 'Logout']
  const defaultValue = () => {
    let current = ''
    ACCOUNT_ROUTES_MENU.map(link => {
      if (isCurrentLink(pathname, link.url)) {
        current = link.label
      }
      return null
    })

    return current
  }
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    ACCOUNT_ROUTES_MENU.map(item => {
      if (e.target.value === item.label) {
        navigate(item.url)
      }
      return null
    })
    if (e.target.value === 'Logout') {
      return navigate(urls.account.logout)
    }
  }
  return (
    <Box as="aside">
      <Flex
        sx={{
          listStyleType: 'none',
          overflowX: 'scroll',
          whiteSpace: 'nowrap',
          borderTop: '1px solid #C8C8C8',
          borderBottom: '1px solid #C8C8C8',
          marginBottom: '15px',
        }}
      >
        {ACCOUNT_ROUTES_MENU.map(link => (
          <RouteLinkMobile
            key={link.url}
            link={link}
            isCurrent={isCurrentLink(pathname, link.url)}
          />
        ))}
        <Box
          sx={{
            minWidth: 'auto',
            margin: 'auto',
            padding: '1em 0.5em 1em 0.5em',
          }}
        >
          <GatsbyLink
            to={urls.account.logout}
            sx={{
              color: ' #505858',
              // color: 'error',
              textDecoration: 'none',
              fontSize: [3],
            }}
            data-cy="logout-link"
          >
            Logout
          </GatsbyLink>
        </Box>
      </Flex>
    </Box>
  )
}

export default AccountAsideMobileScroll

/** @jsx jsx */
import { jsx, Text, Box, Container } from 'theme-ui'
import { FC } from 'react'
import AccountLayout from '~/components/account-layout'
import { useCustomerContext } from '~/context/customer-context'
import Mobile from '~/components/orders-list/mobile'
import { map, path, pipe, prop } from 'ramda'
import { CustomerOrder, CustomerType } from '~/@types/models'
import useMediaQuery from '~/hooks/use-media-query'
import OrdersList from '~/components/orders-list'

type Props = {}

const mapOrders = pipe<CustomerType>(path(['orders', 'edges']), map(prop('node')))

const AccountOrders: FC<Props> = () => {
  const { customer } = useCustomerContext()
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[1]})`,
  })

  const orders = mapOrders(customer) as CustomerOrder[]
  orders.sort((a, b) => parseInt(b.orderNumber) - parseInt(a.orderNumber))

  return (
    <Container variant="container" sx={{ padding: '0' }}>
      {orders.length === 0 ? (
        <Box variant="variants.centerInGrid">
          <Text variant="large">You haven&apos;t placed any orders yet.</Text>
        </Box>
      ) : (
        <Box>{isMobile ? <Mobile orders={orders} /> : <OrdersList orders={orders} />}</Box>
      )}
    </Container>
  )
}

export default AccountOrders

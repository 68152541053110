/** @jsx jsx */
import { Container, jsx } from 'theme-ui'
import { FC } from 'react'
import AccountLayout from '~/components/account-layout'
import urls from '~/common/urls'
import { DashboardProjectGrid } from '~/components/account-layout/components/projectGrid'
import { DashboardPageTitle } from '~/components/account-layout/components/sections'

type Props = {}

const AccountPortfolios: FC<Props> = () => {
  return (
    <Container variant="container" sx={{ padding: '0' }}>
      <DashboardPageTitle
        title="PROJECTS"
        link={urls.account.accountPortfoliosAdd}
        label="CREATE NEW"
        align="space-between"
      />
      <DashboardProjectGrid cardProps={{ showMenu: true }} />
    </Container>
  )
}

export default AccountPortfolios

import styled from 'styled-components'

export const Wrapper = styled.div`
  * {
    text-align: center;
    line-height: 1.2;
  }
  p > a {
    font-family: 'Cormorant Garamond, Times New Roman, serif';
    font-size: 18px;
  }

  p {
    margin: 0;
  }
`

/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { FC } from 'react'
import { LinkType } from '~/@types/models'
import Link from '~/components/link'

type Props = {
  link: LinkType
  isCurrent: boolean
}

const RouteLink3: FC<Props> = ({ link, isCurrent }) => (
  <Box
    as="li"
    sx={{
      fontFamily: 'body',
      fontWeight: 'normal',
      minWidth: 'auto',
      margin: 'auto',
      padding: '1em 0 1em 0.8em',
      '&:before': {
        top: '20px',
      },
    }}
  >
    <Link
      to={link.url}
      sx={{
        textDecoration: 'none',
        fontSize: [3],
        color: '#051826',
        fontWeight: isCurrent ? '700' : '400',
      }}
    >
      {link.label}
    </Link>
  </Box>
)

export default RouteLink3

/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'
import { FC } from 'react'
import { object, string } from 'yup'
import FormField from '~/components/form-field'
import useFormSubmission from '~/hooks/use-form-submission'
import Form from '~/components/form'
import { useCustomerContext } from '~/context/customer-context'
import { space } from '~/gatsby-plugin-theme-ui'

type FormValues = {
  firstName: string
  lastName: string
  email: string
}

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
})

type Props = {
  initialValues?: FormValues
}

const PersonalDataForm: FC<Props> = ({ initialValues = {} }) => {
  const { updateCustomer } = useCustomerContext()
  const submitCallback = async (values: FormValues) => {
    await updateCustomer({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    })
  }

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(submitCallback)

  if (!initialValues) return null

  return (
    <Form
      formProps={{
        onSubmit,
        initialValues,
        validationSchema: schema,
      }}
      buttonLabel="Update"
      isLoading={isLoading}
      serverError={serverError}
    >
      <Grid columns={[1, null, null, 2]} gap={space.gridGap3}>
        <FormField name="firstName" label="First Name" />
        <FormField name="lastName" label="Last Name" />
      </Grid>
      <FormField name="email" label="Email Address" />
    </Form>
  )
}

export default PersonalDataForm

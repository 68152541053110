/** @jsx jsx */
import { jsx, Grid, Flex, Box } from 'theme-ui'
import { object, string } from 'yup'
import useFormSubmission from '~/hooks/use-form-submission'
import FormReadOnly from '~/components/form-read-only'
import { useCustomerContext } from '~/context/customer-context'
import { space } from '~/gatsby-plugin-theme-ui'
import MyModal from '~/templates/account/account-modal'
import ModalUpload from '~/templates/account/account-modal-upload'
import { FC, useState } from 'react'
import FormField from '~/components/form-field'
import ChangePasswordForm from '../change-password-form'
import Pencil from '~/components/icons/pencil'
import { Button } from '~/components/button'
import { useLocation } from '@reach/router'
import NotificationModal from '~/templates/notificationModal'
import {
  DashboardPageTitle,
  DashboardSectionHeader,
} from '~/components/account-layout/components/sections'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  phone: any
}

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
})

type Props = {
  initialValues?: FormValues
}

const PersonalDataForm2: FC<Props> = ({ initialValues = {} }) => {
  const { updateCustomer, updateCustomerBirthdate } = useCustomerContext()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalIsOpen2, setModalIsOpen2] = useState(false)
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [toastText, settoastText] = useState('')
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isMobile, setisMobile] = useState(false)
  const openModal = () => {
    setisMobile(false)
    setModalIsOpen(true)
  }
  const location = useLocation()
  const isProfileEdit = location.pathname.includes('/profile/edit')

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const openModalMobile = () => {
    setisMobile(true)
    setModalIsOpen(true)
  }
  const openModal2 = () => {
    setModalIsOpen2(true)
  }
  const closeModal2 = () => {
    setModalIsOpen2(false)
  }
  const handleOpenNavClick = () => {
    setIsNavOpen(!isNavOpen)
  }
  const [isRead, setIsRead] = useState(!isProfileEdit)
  const [isCancel, setIsCancel] = useState(false)
  const editProfile = () => {
    setIsRead(prevIsRead => !prevIsRead)
  }

  const submitCallback = async (values: FormValues) => {
    let phoneFormated = null
    if (values.phone) {
      phoneFormated = values.phone.slice(0, 2) === '+1' ? values.phone : '+1' + values.phone
    }

    if (isCancel) {
      if (!isRead) {
        setIsCancel(false)
        setIsRead(prevIsRead => !prevIsRead)
        return
      }
    }

    // console.log(values);
    await updateCustomer({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: phoneFormated,
    })
      .then(function (response) {
        settoastText('Changes saved')
        settoastlIsOpen(true)
        if (!isRead) {
          setIsRead(prevIsRead => !prevIsRead)
          setTimeout(() => {
            settoastlIsOpen(false)
          }, 3200)
          return
        }
      })
      .catch(function () {
        settoastText('Could not save the changes')
        setisSuccesful(false)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
        }, 2200)
      })
  }

  const closeToast = () => {
    settoastlIsOpen(false)
  }

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(submitCallback)

  const dataDummy = [
    {
      firstCol: 'Document name',
      secondCol: 'Document type description',
      thirdCol: 'September 15, 2023',
    },
  ]
  return (
    <div>
      {toastlIsOpen && (
        <Box>
          <NotificationModal
            toastText={toastText}
            isFromModal={true}
            isSuccesful={isSuccesful}
            isMobile={false}
            isOpen={toastlIsOpen}
            onRequestClose={closeToast}
          />
        </Box>
      )}
      <DashboardPageTitle
        title="PROFILE"
        align="space-between"
        label="EDIT"
        onClick={editProfile}
      />
      <Box
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          mb: [space.inputGap],
        }}
      >
        <Box className="desktop">
          {/* <Flex sx={{ justifyContent: 'right', display: isRead ? '' : 'none' }}>
            <Box sx={{ margin: 'auto 0 auto 0' }}>
              <Button
                label="EDIT"
                as="link"
                variant="buttons.link"
                sxProps={{
                  display: 'inline-block',
                  minWidth: 'auto',
                  height: 'auto',
                  color: '#2b2b2b',
                  border: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={editProfile}
              />
            </Box>
          </Flex> */}
          <span
            sx={{
              textDecorationLine: 'underline',
              lineHeight: '18px',
              fontFamily: 'Arial',
              fontWeight: 400,
              fontSize: '16px',
              color: '#2b2b2b',
              padding: '0 0 0 10px',
              cursor: 'pointer',
              display: 'none',
            }}
            onClick={openModal}
          >
            CHANGE PASSWORD
          </span>
        </Box>
        {/* <Box className="mobile" onClick={editProfile} sx={{ textAlign: 'end' }}>
          <Pencil color="#2b2b2b" width="24px" height="24px" />
        </Box> */}
      </Box>
      {isNavOpen && (
        <Box
          sx={{
            position: 'absolute',
            bg: 'main',
            border: '1px solid #C8C8C8',
            overflowY: 'auto',
            width: '58%',
            padding: '10px',
            marginLeft: '30%',
          }}
        >
          <Box as="nav" className="menu">
            <Box
              sx={{
                '.menu-link': {
                  color: 'zambezi',
                  textDecoration: 'none',
                  fontSize: ['12px', '14px', '16px'],
                  border: '1px solid',
                  borderColor: 'border',
                  display: 'block',
                  px: space.md2,
                  py: 20,
                  background: 'transparent',
                  textAlign: 'left',
                  mt: -1,
                  ':hover': {
                    textDecoration: 'underline',
                    color: 'text',
                  },
                  ul: {
                    mergeLeft: 20,
                  },
                },
                '.hasSubmenu .menu-link:hover': {
                  textDecoration: 'none',
                },
                li: {
                  position: 'relative',
                },
                ul: {
                  variant: 'variants.list.none',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                },
                'ul ul .menu-link': {
                  color: 'muted',
                  ':hover': {
                    textDecoration: 'underline',
                    color: 'text',
                  },
                },
                'ul ul': {
                  display: 'none',
                },
                'ul ul > li > .menu-link': {
                  pl: 40,
                },
                'ul ul ul > li > .menu-link': {
                  pl: 60,
                },
                'ul ul ul ul > li > .menu-link': {
                  pl: 80,
                },
              }}
            >
              <ul>
                <li>
                  <span
                    sx={{
                      textDecorationLine: 'underline',
                      lineHeight: '38px',
                      fontFamily: 'Arial',
                      fontWeight: 400,
                      fontSize: '16px',
                      color: '#2b2b2b',
                      cursor: 'pointer',
                    }}
                  >
                    EDIT PROFILE
                  </span>
                </li>
                <li>
                  <span
                    sx={{
                      textDecorationLine: 'underline',
                      lineHeight: '38px',
                      fontFamily: 'Arial',
                      fontWeight: 400,
                      fontSize: '16px',
                      color: '#2b2b2b',
                      cursor: 'pointer',
                    }}
                    onClick={openModalMobile}
                  >
                    CHANGE PASSWORD
                  </span>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      )}
      <FormReadOnly
        formProps={{
          onSubmit,
          initialValues,
          validationSchema: schema,
        }}
        isLoading={isLoading}
        serverError={serverError}
        isRead={isRead}
        setIsRead={setIsRead}
        setIsCancel={setIsCancel}
        isCancel={isCancel}
      >
        <Grid columns={[1, null, null, 2]} gap="1vw">
          <FormField
            disabled={isRead}
            as={isRead ? 'input-read' : 'input'}
            name="firstName"
            label="Name"
          />
          <FormField
            disabled={isRead}
            as={isRead ? 'input-read' : 'input'}
            name="lastName"
            label="Last Name"
          />
        </Grid>
        <Grid columns={[1, null, null, 2]} gap="1vw">
          <FormField
            disabled={isRead}
            as={isRead ? 'input-read' : 'input'}
            name="email"
            label="Email Address"
          />
          <FormField
            // disabled={true}
            disabled={isRead}
            as={isRead ? 'input-read' : 'input'}
            name="phone"
            label="Phone Number"
          />
        </Grid>
        <div>
          <MyModal isMobile={isMobile} isOpen={modalIsOpen} onRequestClose={closeModal} />
        </div>
        <div>
          <ModalUpload isOpen={modalIsOpen2} onRequestClose={closeModal2} />
        </div>
      </FormReadOnly>
      <Flex sx={{ mt: [30] }}>
        <h3>UPDATE PASSWORD</h3>
      </Flex>
      <ChangePasswordForm />
    </div>
  )
}

export default PersonalDataForm2

import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  color?: string
}

export const ArrowSelector: FC<Props> = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#2b2b2b"
      style={{ margin: 'auto' }}
    >
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  )
}

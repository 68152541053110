/** @jsx jsx */
import {
  jsx,
  Text,
  Box,
  Grid,
  Container,
  Heading,
  Message,
  Flex,
  Card,
  Image,
  SxStyleProp,
} from 'theme-ui'
import React, { FC } from 'react'
import { useCustomerContext } from '~/context/customer-context'
import { path, pipe, prop, map, find, propEq } from 'ramda'
import { CustomerOrder } from '~/@types/models'
import { formatDate, formatMoney } from '~/utils/format'
import { space } from '~/gatsby-plugin-theme-ui'
import ImageWrapper from '~/components/image-wrapper'
import { ColorBoxV2 } from '~/componentsV2/colors/colorBox'

type Props = {
  id: string
}

type BodyBoxProps = {
  sxProps?: SxStyleProp
}

const BodyBox: FC<BodyBoxProps> = ({ children, sxProps }) => (
  <Box
    sx={{
      color: 'txt',
      fontSize: [3],
      ...sxProps,
    }}
  >
    {children}
  </Box>
)

const HeaderRow: FC = ({ children }) => (
  <Box
    sx={{
      color: '#051826',
      fontSize: [3],
      mb: [37],
      fontWeight: 700,
    }}
  >
    {children}
  </Box>
)

const OrderRow: FC = order => {
  const orderEdges = order.lineItems.edges
  const orderItems: string[] = []
  orderEdges.map((element: any) => {
    return orderItems.push(element.node.title)
  })
  const countOfItems = order.lineItems.edges.length

  return (
    <React.Fragment>
      <BodyBox>{order.orderNumber}</BodyBox>
      <BodyBox>{formatDate(order.processedAt)}</BodyBox>
      <BodyBox>
        $ {order.totalPrice.amount}
        {/* for {countOfItems} {countOfItems > 1 ? 'items' : 'item'} */}
      </BodyBox>
      <BodyBox>
        <Box as="span" variant="variants.firstLetterToUppercase">
          {String(order.fulfillmentStatus)}
        </Box>
      </BodyBox>
    </React.Fragment>
  )
}

const getOrderById = (id: string) =>
  pipe(path(['orders', 'edges']), map(prop('node')), find<CustomerOrder>(propEq('id', id)))

const AccountOrdersDetail: FC<Props> = ({ id }) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const id2 = urlParams.get('key')
  const { customer } = useCustomerContext()
  const concatId = 'gid://shopify/Order/' + id + '?key=' + id2
  const order = getOrderById(concatId)(customer)

  if (!order) {
    return <Message>Order with id ${id} doesn&apos;t exist</Message>
  }

  return (
    <Box>
      <Container variant="container">
        <Flex>
          <h1
            sx={{
              fontFamily: 'Cormorant Garamond, Times New Roman, serif',
              fontWeight: 400,
              fontSize: '2.3rem',
              mt: [0],
              lineHeight: '48.44px',
              color: '#2b2b2b',
              mb: [36],
              marginRight: ['10px', '20px'],
            }}
          >
            ORDER # {order.orderNumber}
          </h1>
        </Flex>
        <Grid
          columns={[4, '10% 25% 25% 20%']}
          sx={{
            // borderBottom: t => `1px solid ${t.colors.strokes}`,
            mb: [12],
          }}
          gap={space.md}
        >
          <HeaderRow>Order #</HeaderRow>
          <HeaderRow>Date</HeaderRow>
          <HeaderRow>Total</HeaderRow>
          <HeaderRow>Status</HeaderRow>
        </Grid>
        <Grid
          key={order.id}
          columns={[4, '10% 25% 25% 20%']}
          gap={space.md}
          sx={{
            alignItems: 'center',
            mb: [30],
          }}
        >
          <OrderRow {...order} />
        </Grid>
        <Grid
          columns={['1fr', null, '1.5fr 1fr']}
          gap={space.gridGap3}
          as="section"
          sx={{
            mb: [100],
          }}
        >
          {order.lineItems.edges.map((edge, i) => {
            const { title, originalTotalPrice, variant } = edge.node
            return (
              <Grid
                sx={{
                  alignItems: 'center',
                  flexWrap: ['wrap', null, 'nowrap'],
                }}
                gap={space.gridGap2}
              >
                <Box
                  sx={{
                    width: ['100%', null, 272],
                    mr: [0, null, 56],
                    mb: [space.md3, null, 0],
                  }}
                >
                  <ImageWrapper ratio={4 / 3} center>
                    {variant?.image ? (
                      <Image
                        src={variant?.image.originalSrc}
                        loading="lazy"
                        alt={variant?.selectedOptions[0].value || 'Product Image'}
                      />
                    ) : (
                      <Box>
                        <Text>No Image</Text>
                      </Box>
                    )}
                  </ImageWrapper>
                </Box>
                <Box
                  sx={{
                    flex: ['100%', null, 1],
                  }}
                >
                  <Text
                    sx={{
                      fontSize: [23],
                      lineHeight: ['34.5px'],
                      fontWeight: [600],
                      color: 'text',
                      fontFamily: 'heading',
                      textTransform: 'uppercase',
                    }}
                  >
                    {title}
                  </Text>
                  {variant?.image ? (
                    <Box
                      as="li"
                      key={variant?.image.transformedSrc}
                      sx={{
                        listStyle: 'none',
                        p: ['1px', '4px'],
                        width: [30, 60],
                        height: [30, 60],
                        mt: [20],
                      }}
                    >
                      <ColorBoxV2 value="" src={variant?.image.transformedSrc} selectedValue="" />
                    </Box>
                  ) : null}
                </Box>
                <Box
                  as={Text}
                  sx={{
                    fontSize: [3],
                    lineHeight: '36px',
                    fontWeight: 'bold',
                    color: 'text',
                  }}
                >
                  {formatMoney(originalTotalPrice.amount)}
                </Box>
              </Grid>
            )
          })}
        </Grid>

        {/* <Box
          as="section"
          sx={{
            mb: [100],
          }}
        >
          <Heading
            sx={{
              mb: [37],
              fontSize: [6, 6, 6],
              lineHeight: '40px',
            }}
          >
            Order Summary
          </Heading>
          <Grid
            columns={['2fr 1fr']}
            gap={0}
            sx={{
              color: 'txt',
              div: {
                mb: [14],
                fontSize: [3],
                lineHeight: ['34px'],
              },
            }}
          >
            <Text>Subtotal</Text>
            <Text
              sx={{
                textAlign: 'right',
              }}
            >
              {formatMoney(order.subtotalPrice.amount)}
            </Text>
            <Text>
              Delivery
              <br />
              via Scheduled Threshold (approximately 1 - 2 weeks)
            </Text>
            <Text
              sx={{
                textAlign: 'right',
              }}
            >
              {formatMoney(order.totalShippingPrice.amount)}
            </Text>
            <Text>Taxes</Text>
            <Text
              sx={{
                textAlign: 'right',
              }}
            >
              {formatMoney(order.currentTotalTax.amount)}
            </Text>
            <Text
              sx={{
                borderTop: '1px solid',
                pt: space.md,
              }}
              color="text"
            >
              Total
            </Text>
            <Text
              sx={{
                borderTop: '1px solid',
                pt: space.md,
                color: 'text',
                textAlign: 'right',
              }}
            >
              {formatMoney(order.totalPrice.amount)}
            </Text>
          </Grid>
        </Box> */}
        <Box as="section">
          <Grid columns={[1, 2, 3]}>
            {/* <Box>
              <Heading
                as="h3"
                sx={{
                  fontSize: [5, 5, 5],
                  lineHeight: ['36px'],
                  mb: [23],
                }}
              >
                Billing Address
              </Heading>
              {order.shippingAddress ? (
                <Address address={order.shippingAddress} />
              ) : (
                <Text>No billing address</Text>
              )}
            </Box> */}
            <Box>
              <Heading
                as="h3"
                sx={{
                  fontSize: [5, 5, 5],
                  lineHeight: ['36px'],
                  mb: [23],
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: '#585858',
                }}
              >
                Shipping Address
              </Heading>
              {order.shippingAddress ? (
                // <Address address={order.shippingAddress} />
                <Card
                  key={order.shippingAddress.id}
                  sx={{
                    border: 'none',
                    // padding: '20px',
                  }}
                >
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Text
                      sx={{
                        fontFamily: 'Helvetica, Times New Roman, serif',
                        fontWeight: 400,
                        fontSize: '18px',
                        lineHeight: '20px',
                        marginTop: '0.4em',
                        marginBottom: '0.4em',
                        color: '#2b2b2b',
                      }}
                    >
                      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                    </Text>
                    <Text
                      sx={{
                        fontFamily: 'Helvetica, Times New Roman, serif',
                        fontWeight: 400,
                        fontSize: '18px',
                        lineHeight: '20px',
                        marginTop: '0.4em',
                        marginBottom: '0.4em',
                        color: '#2b2b2b',
                      }}
                    >
                      {order.shippingAddress.address1}
                    </Text>
                    <Text
                      sx={{
                        fontFamily: 'Helvetica, Times New Roman, serif',
                        fontWeight: 400,
                        fontSize: '18px',
                        lineHeight: '20px',
                        marginTop: '0.4em',
                        marginBottom: '0.4em',
                        color: '#2b2b2b',
                      }}
                    >
                      {' '}
                      {order.shippingAddress.address2}
                    </Text>
                    <Text
                      sx={{
                        fontFamily: 'Helvetica, Times New Roman, serif',
                        fontWeight: 400,
                        fontSize: '18px',
                        lineHeight: '20px',
                        marginTop: '0.4em',
                        marginBottom: '0.4em',
                        color: '#2b2b2b',
                      }}
                    >
                      {' '}
                      {order.shippingAddress.city}, {order.shippingAddress.province},{' '}
                      {order.shippingAddress.zip}
                    </Text>
                    <Text
                      sx={{
                        fontFamily: 'Helvetica, Times New Roman, serif',
                        fontWeight: 400,
                        fontSize: '18px',
                        lineHeight: '20px',
                        marginTop: '0.4em',
                        marginBottom: '0.4em',
                        color: '#2b2b2b',
                      }}
                    >
                      {' '}
                      {order.shippingAddress.country}
                    </Text>
                    <Text
                      sx={{
                        fontFamily: 'Helvetica, Times New Roman, serif',
                        fontWeight: 400,
                        fontSize: '18px',
                        lineHeight: '20px',
                        marginTop: '0.4em',
                        marginBottom: '0.4em',
                        color: '#2b2b2b',
                      }}
                    >
                      {order.shippingAddress.phone}
                    </Text>
                    {order.shippingAddress.isDefault && (
                      <Text
                        sx={{
                          fontFamily: 'Helvetica, Times New Roman, serif',
                          padding: '5px 15px 5px 15px',
                          textTransform: 'uppercase',
                          color: '#FFFFFF',
                          fontWeight: 400,
                          fontSize: [2],
                          background: '#585858',
                          textAlign: 'center',
                          position: 'relative',
                          width: '30%',
                          mt: [10],
                        }}
                      >
                        DEFAULT
                      </Text>
                    )}
                  </Flex>
                </Card>
              ) : (
                <Text>No shipping address</Text>
              )}
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default AccountOrdersDetail

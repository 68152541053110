/** @jsx jsx */
import { Container, jsx } from 'theme-ui'
import { FC } from 'react'
import AccountLayout from '~/components/account-layout'
import { DashboardPageTitle } from '~/components/account-layout/components/sections'
import { DashboardSflGrid } from '~/components/account-layout/components/saveForLaterGrid'
import { DashboardSflCarousel } from '~/components/account-layout/components/saveForLaterCarousel'

type Props = {}

const AccountSaveForLater: FC<Props> = () => {
  return (
    <Container variant="container" sx={{ padding: '0' }}>
      <DashboardPageTitle title="YOUR SAVED ITEMS" align="space-between" />
      <DashboardSflGrid cardProps={{ showMenu: true }} />
    </Container>
  )
}

export default AccountSaveForLater

// @ts-ignore
import { Button, SxStyleProp, AspectImage } from 'theme-ui'
import React, { FC } from 'react'

type Props = Partial<HTMLButtonElement> & {
  defaultSx?: SxStyleProp
  src: string
  alt: string
}

export const ColorBoxInner = ({ defaultSx = {}, src, alt, ...rest }: Props) => {
  return (
    <Button
      sx={{
        width: '55px',
        height: '55px',
        '@media screen and (max-width: 640px)': {
          width: '40px',
          height: '40px',
        },
        bg: 'bg',
        borderRadius: 0,
        mr: 'sm',
        mb: 'sm',
        cursor: 'pointer',
        border: `1px solid`,
        ...defaultSx,
      }}
      {...rest}
    >
      <AspectImage src={src} alt={alt} ratio={1} />
    </Button>
  )
}

/** @jsx jsx */
import { jsx, Container, Box } from 'theme-ui'
import { FC } from 'react'
import { pick } from 'ramda'

import PersonalDataForm from '~/containers/personal-data-form'
import ChangePasswordForm from '~/containers/change-password-form'
import AccountLayout from '~/components/account-layout'
import { useCustomerContext } from '~/context/customer-context'
import { navigate } from 'gatsby'

type Props = {}

const AccountTemplate: FC<Props> = () => {
  const { customer, isLoggedIn } = useCustomerContext()

  if (!isLoggedIn) {
    return null
  }

  // if (!customer) {
  //   navigate('/sign-in')

  //   return null
  // }

  return (
    <Container variant="mid">
      <Box
        sx={{
          mb: [124],
        }}
      >
        <PersonalDataForm initialValues={pick(['firstName', 'lastName', 'email'], customer)} />
      </Box>
      <Box>
        <ChangePasswordForm />
      </Box>
    </Container>
  )
}

export default AccountTemplate

/** @jsx jsx */
import { Container, jsx, Flex } from 'theme-ui'
import { FC } from 'react'
import AddressForm2 from '~/containers/address-form2'
import { useCustomerContext } from '~/context/customer-context'

type Props = {}

const AccountAddressesAdd: FC<Props> = () => {
  const { customer } = useCustomerContext()

  const addNumber = customer.addresses.edges.length + 1
  return (
    <Container variant="container">
      <Flex>
        <h1
          sx={{
            fontFamily: 'Cormorant Garamond, Times New Roman, serif',
            fontWeight: 400,
            fontSize: '40px',
            mt: [0],
            lineHeight: '48.44px',
            color: '#2b2b2b',
            mb: [36],
            marginRight: ['10px', '20px'],
          }}
        >
          NEW SHIPPING ADDRESSES
        </h1>
      </Flex>
      <AddressForm2 isNew={true} isRead2={false} addNumber={addNumber} />
    </Container>
  )
}

export default AccountAddressesAdd

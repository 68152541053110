import React from 'react'

import { useIdentifierModal } from '~/context/identifier-modal'
import { DashboardTag } from './sections'
import { useCustomerContext } from '~/context/customer-context'

export const CustomerSegment = () => {
  const { isTrade } = useCustomerContext()

  return <DashboardTag variant="secondary">{isTrade ? 'Trade' : 'Customer'}</DashboardTag>
}

/** @jsx jsx */
import { jsx, Grid, Flex, Box, Text } from 'theme-ui'
import { FC, useCallback, useState } from 'react'
import { object, string } from 'yup'
import FormField from '~/components/form-field'
import useFormSubmission from '~/hooks/use-form-submission'
import { states } from '~/data/states'
import FormRow from '~/components/form-row'
import { useCustomerContext } from '~/context/customer-context'
import FormReadOnly from '~/components/form-read-only'
import { navigate } from 'gatsby-link'
import urls from '~/common/urls'
import { Button } from '~/components/button'
import TrashCan from '~/components/icons/trashCan'
import NotificationModal from '~/templates/notificationModal'
import {
  DashboardHeaderAction,
  DashboardTag,
} from '~/components/account-layout/components/sections'

type FormValues = {
  id: string
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  province: string
  zip: string
  phone: string
  isDefault?: boolean
}

const schema = object().shape({
  firstName: string().min(2).required(),
  lastName: string().min(2).required(),
  company: string(),
  address1: string().required(),
  address2: string(),
  city: string().required(),
  country: string().required(),
  province: string().required(),
  zip: string().required(),
  phone: string(),
})

type Props = {
  initialValues?: FormValues
  isEdit?: boolean
  isRead2?: boolean
  isNew?: boolean
  addNumber: number
  index?: number
  myCheckbox?: boolean
}

const AddressForm2: FC<Props> = ({
  initialValues = {
    id: '',
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    country: 'United States',
    state: '',
    zip: '',
    phone: '',
    isDefault: false,
  },
  isEdit = false,
  isRead2 = true,
  isNew = false,
  index = 0,
  addNumber = 0,
}) => {
  const { addAddress, updateAddress, deleteAddress, updateDefaultAddress } = useCustomerContext()
  const [isRead, setIsRead] = useState(isRead2)
  const [isChecked, setisChecked] = useState(false)
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const [toastText, settoastText] = useState('')
  const [wishlistName, setwishlistName] = useState('')
  const [isCancel, setIsCancel] = useState(false)
  const openToast = async (copyBoolean: boolean) => {
    settoastlIsOpen(true)
  }

  const closeToast = () => {
    settoastlIsOpen(false)
  }
  const submitCallback = async (values: FormValues) => {
    if (isRead) {
      return
    }
    if (isCancel) {
      if (!isRead) {
        setIsCancel(false)
        setIsRead(prevIsRead => !prevIsRead)
        return
      }
    }
    const requestValues = {
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      company: values.company,
      country: values.country,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      province: values.province,
      zip: values.zip,
    }

    if (isEdit) {
      const addressId = initialValues.id
      settoastText('Address edited successfully')
      setisSuccesful(true)
      settoastlIsOpen(true)
      setTimeout(() => {
        settoastlIsOpen(false)
      }, 3200)

      await updateAddress(addressId, requestValues)
        .then(function (response) {
          if (isChecked) {
            updateDefaultAddress(addressId).catch(function (error) {
              return error
            })
          }
          settoastText('Address edited successfully')
          setisSuccesful(true)
          settoastlIsOpen(true)
          setTimeout(() => {
            settoastlIsOpen(false)
          }, 3200)
        })
        .catch(function () {
          settoastText('Address could not be edited')
          setisSuccesful(false)
          settoastlIsOpen(true)
          setTimeout(() => {
            settoastlIsOpen(false)
          }, 3200)
        })
    } else {
      try {
        await addAddress(requestValues)
        await settoastText('Address created successfully')
        await setisSuccesful(true)
        await settoastlIsOpen(true)
        await setTimeout(() => {
          settoastlIsOpen(false)
          return navigate(urls.account.accountAddresses)
        }, 3200)
      } catch (error) {
        await settoastText('Address could not be created')
        await setisSuccesful(false)
        await settoastlIsOpen(true)
        await setTimeout(() => {
          settoastlIsOpen(false)
        }, 3200)
      }
    }
  }

  const handleDeleteAddress = useCallback(async (addressId: string) => {
    try {
      await settoastText('Address deleted successfully')
      await setisSuccesful(true)
      await settoastlIsOpen(true)
      await setTimeout(() => {
        settoastlIsOpen(false)
      }, 3200)
      await deleteAddress(addressId)
    } catch (error) {
      await settoastText('Address could not be deleted')
      await setisSuccesful(false)
      await settoastlIsOpen(true)
      await setTimeout(() => {
        settoastlIsOpen(false)
      }, 3200)
    }
  }, [])

  const handleCheckDefault = useCallback(() => {
    setisChecked(prevIsDefault => !prevIsDefault) // Toggle the value
  }, [])
  const handleEditAddress = () => {
    setIsRead(prevIsRead => !prevIsRead) // Toggle the value
  }

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(submitCallback)

  return (
    <FormReadOnly
      formProps={{
        onSubmit,
        initialValues,
        validationSchema: schema,
      }}
      isLoading={isLoading}
      serverError={serverError}
      isRead={isRead}
      setIsRead={setIsRead}
      isNew={isNew}
      setIsCancel={setIsCancel}
    >
      <Grid columns={[2, null, null, 2]} sx={{}}>
        <Flex
          sx={{
            mt: [30],
            mb: [30],
            alignItems: 'center',
          }}
        >
          <h3
            sx={{
              fontWeight: 200,
              marginRight: ['10px', '20px'],
            }}
          >
            ADDRESS # {addNumber}
          </h3>

          {initialValues.isDefault && (
            <DashboardTag variant="secondary" style={{}}>
              DEFAULT
            </DashboardTag>
          )}
        </Flex>
        <Flex sx={{ justifyContent: 'right', display: !isRead && !isNew ? '' : 'none' }}>
          <Box sx={{ margin: 'auto 0 auto 0' }}>
            {/* <TrashCan color="#2b2b2b" width="24px" height="24px" /> */}
            <Button
              label="Delete"
              as="link"
              variant="buttons.link"
              sxProps={{
                display: 'inline-block',
                minWidth: 'auto',
                // p: 0,
                height: 'auto',
                color: '#2b2b2b',
                border: 'none',
                textTransform: 'uppercase',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                handleDeleteAddress(initialValues.id)
              }}
            ></Button>
          </Box>
        </Flex>
        <Flex sx={{ justifyContent: 'right', display: isRead ? '' : 'none' }}>
          <Box sx={{ margin: 'auto 0 auto 0' }}>
            <DashboardHeaderAction
              label="EDIT"
              onClick={() => {
                handleEditAddress()
              }}
            />
          </Box>
        </Flex>
      </Grid>
      <FormRow columns={[1, 2]}>
        <FormField labelUppercase={true} disabled={isRead} name="firstName" label="First Name" />
        <FormField labelUppercase={true} disabled={isRead} name="lastName" label="Last Name" />
      </FormRow>
      <FormRow columns={[1, 2]}>
        <FormField labelUppercase={true} disabled={isRead} name="address1" label="Address 1" />
        <FormField labelUppercase={true} disabled={isRead} name="address2" label="Address 2" />
      </FormRow>
      <FormRow columns={[1, 2]}>
        <FormField labelUppercase={true} disabled={isRead} name="city" label="City" />
        <FormField
          name="province"
          sx={{ maxHeight: '40px', color: '#2b2b2b', opacity: '1' }}
          label="State"
          as="select"
          disabled={isRead}
          options={Object.values(states)}
          labelUppercase={true}
          selectAsImput={true}
        />
      </FormRow>
      <FormRow columns={[1, 2]}>
        <FormField labelUppercase={true} disabled={isRead} name="zip" label="Zip code" />
        <FormField labelUppercase={true} disabled={true} name="country" label="Country" />
      </FormRow>
      <FormRow columns={[1, 2]} sx={{ display: isRead && !initialValues.phone ? 'none' : '' }}>
        <FormField labelUppercase={true} disabled={isRead} name="phone" label="Phone" />
      </FormRow>
      {!initialValues.isDefault && !isNew && !isRead && (
        <Box style={{}}>
          <Box
            as="input"
            type="checkbox"
            onChange={handleCheckDefault}
            sx={{
              background: 'red',
              cursor: 'pointer',
            }}
          />
          <Text
            sx={{
              ml: [10],
              color: '#2b2b2b',
            }}
          >
            Make this address default.
          </Text>
        </Box>
      )}
      {toastlIsOpen && (
        <Box>
          <NotificationModal
            toastText={toastText}
            wishlistName={wishlistName}
            isFromModal={true}
            isSuccesful={isSuccesful}
            isMobile={false}
            isOpen={toastlIsOpen}
            onRequestClose={closeToast}
          />
        </Box>
      )}
    </FormReadOnly>
  )
}

export default AddressForm2

import urls from '~/common/urls'

import AccountOrders from '~/templates/account/account-orders'
import AccountOrdersDetail from '~/templates/account/account-orders-detail'
import AccountAddresses2 from '~/templates/account/account-addresses2'
import AccountAddressesAdd from '~/templates/account/account-addresses-add'
import AccountAddressesEdit from '~/templates/account/account-addresses-edit'
import AccountDashboard from '~/templates/account/account-dashboard'
import AccountProfile from '~/templates/account/account-profile'
import AccountPaymentMethod from '~/templates/account/account-payment-method'
import AccountPaymentMethodAdd from '~/templates/account/account-payment-methods-add'
import AccountPortfolios from '~/templates/account/account-portfolios'
import AccountPortfoliosAdd from '~/templates/account/account-portfolios-add'
import AccountPortfoliosDetail from '~/templates/account/account-portfolios-detail'
import AccountPortfoliosShared from '~/templates/account/account-portfolio-shared'
import AccountPortfoliosEdit from '~/templates/account/account-portfolios-edit'
import AccountTemplate from '~/templates/account/account'
import AccountSaveForLater from '~/templates/account/account-save-for-later'

const {
  accountDashboard,
  accountProfile,
  accountPersonalData,
  accountOrders,
  accountAddresses,
  accountAddressesAdd,
  accountAddressesEdit,
  accountPaymentMethods,
  accountPaymentMethodsAdd,
  accountPortfolios,
  accountPortfoliosAdd,
  accountPortfoliosEdit,
  accountPortfoliosDetail,
  accountPortfoliosShared,
  accountSaveForLater,
} = urls.account

export const ACCOUNT_ROUTES = [
  {
    path: accountDashboard,
    component: AccountDashboard,
    menu: 'Overview',
  },
  {
    path: accountProfile,
    component: AccountProfile,
    menu: 'Profile',
  },
  {
    path: accountPersonalData,
    component: AccountTemplate,
  },
  {
    path: accountProfile + '/edit',
    component: AccountProfile,
  },
  {
    path: accountOrders,
    component: AccountOrders,
    menu: 'Orders',
  },
  {
    path: accountOrders + '/:id',
    component: AccountOrdersDetail,
  },
  {
    path: accountPortfolios,
    component: AccountPortfolios,
    menu: 'Projects',
  },
  {
    path: accountSaveForLater,
    component: AccountSaveForLater,
    menu: 'Saved Items',
  },
  {
    path: accountPortfolios + '/:id',
    component: AccountPortfoliosDetail,
  },
  {
    path: accountPortfolios + '/add',
    component: AccountPortfoliosAdd,
  },
  {
    path: accountPortfolios + '/edit/:id',
    component: AccountPortfoliosEdit,
  },
  {
    path: accountAddresses,
    component: AccountAddresses2,
    menu: 'Addresses',
  },
  {
    path: accountAddresses + '/add',
    component: AccountAddressesAdd,
  },
  {
    path: accountAddresses + '/edit/',
    component: AccountAddressesEdit,
  },
  {
    path: accountPaymentMethods,
    component: AccountPaymentMethod,
  },
  {
    path: accountPaymentMethods + '/add',
    component: AccountPaymentMethodAdd,
  },
  {
    path: accountPortfoliosShared,
    component: AccountPortfoliosShared,
  },
]

export const ACCOUNT_ROUTES_MENU = ACCOUNT_ROUTES.filter(item => !!item.menu).map(item => ({
  label: item.menu as string,
  url: item.path,
}))

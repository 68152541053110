/** @jsx jsx */
import { Container, jsx, Box, Flex } from 'theme-ui'
import { FC, useEffect, useState } from 'react'
import urls from '~/common/urls'
import FormReadOnly from '~/components/form-read-only'
import FormField from '~/components/form-field'
import FormRow from '~/components/form-row'
import useFormSubmission from '~/hooks/use-form-submission'
import { useCustomerContext } from '~/context/customer-context'
import { navigate } from 'gatsby'
import NotificationModal from '../notificationModal'
import { axiosRequests } from '~/utils/axios'

type Props = {
  initialValues?: FormValues
}

type FormValues = {
  projectName: string
}

const AccountPortfoliosAdd: FC<Props> = ({
  initialValues = {
    projectName: '',
  },
}) => {
  const AxiosFunctions = axiosRequests()
  const [loading, setLoading] = useState<boolean>(false)
  const options: readonly string[] = ['PORTFOLIOS NAME']
  const { customer, createWishlist } = useCustomerContext()
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const [toastText, settoastText] = useState('')
  const [wishlistName, setwishlistName] = useState('')
  const [regid, setRegid] = useState('')
  const [sessionid, setSessionid] = useState('')
  const [isCancel, setIsCancel] = useState(false)
  const [ResponseData, setResponseData] = useState()

  const createWishlists = async listName => {
    await AxiosFunctions.createWishlists(listName, ResponseData)
      .then(function () {
        settoastText('Project created')
        setisSuccesful(true)
        settoastlIsOpen(true)
        setTimeout(() => {
          return navigate(urls.account.accountPortfolios)
        }, 3200)
      })
      .catch(function () {
        settoastText('Could not create project')
        setisSuccesful(false)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
        }, 3200)
      })
  }

  const submitCallback = async (values: FormValues) => {
    if (isCancel) {
      setIsCancel(false)
      return navigate(urls.account.accountPortfolios)
    }
    const requestValues = {
      projectName: values.projectName,
    }
    if (customer) {
      await createWishlists(requestValues.projectName)
    }
  }

  const genRegid = async () => {
    await AxiosFunctions.genRegId(customer?.email)
      .then(function (response) {
        setRegid(response.data.regid)
        setSessionid(response.data.sessionid)
        setResponseData(response)
      })
      .catch(function () {
        // console.log(error);
      })
  }

  useEffect(() => {
    genRegid()
  }, [])

  const openToast = async (copyBoolean: boolean) => {
    settoastlIsOpen(true)
  }

  const closeToast = () => {
    settoastlIsOpen(false)
  }

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(submitCallback)

  return (
    <Box>
      <Container variant="container">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Flex>
            <h1
              sx={{
                fontFamily: 'Cormorant Garamond, Times New Roman, serif',
                fontWeight: 400,
                fontSize: '40px',
                mt: [0],
                lineHeight: '48.44px',
                color: '#2b2b2b',
                mb: [30],
                marginRight: ['10px', '20px'],
              }}
            >
              CREATE NEW PROJECT
            </h1>
          </Flex>
        </Box>
        <FormReadOnly
          formProps={{
            onSubmit,
            initialValues,
          }}
          setIsCancel={setIsCancel}
          isLoading={isLoading}
          serverError={serverError}
        >
          <FormRow columns={[1, 2]}>
            <FormField name="projectName" label="Project Name" />
          </FormRow>
        </FormReadOnly>
        {toastlIsOpen && (
          <Box>
            <NotificationModal
              toastText={toastText}
              wishlistName={wishlistName}
              isFromModal={true}
              isSuccesful={isSuccesful}
              isMobile={false}
              isOpen={toastlIsOpen}
              onRequestClose={closeToast}
            />
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default AccountPortfoliosAdd

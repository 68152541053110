import React, { useCallback, useEffect } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import CardSection, { CardSectionData } from '~/componentsV2/pageBuilder/sections/CardSection'
import { parseCmsData } from '~/componentsV2/pageBuilder/utils'
import { useIdentifierModal } from '~/context/identifier-modal'

import { cmsEnv } from '~/common/cms'

interface QueryResult {
  data: {
    marketingZone: CardSectionData[]
  }
}

const dashboardQuery = graphql`
  query {
    data: dashboardCms {
      marketingZone
    }
  }
`

export const loadData = async (isDraft: boolean): Promise<any> => {
  const res = await fetch(
    `${cmsEnv.endpoint}/dashboard?populate=deep${isDraft ? '&draft=true' : ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${cmsEnv.token}`,
      },
    }
  )
  return (await res.json()).data
}

export const MarketingArea = () => {
  const {
    data: { marketingZone },
  } = useStaticQuery<QueryResult>(dashboardQuery)
  const { customerSegment } = useIdentifierModal()

  const [currentData, setData] = React.useState<CardSectionData[]>([])

  const parseSegmentContent = useCallback(
    (content: CardSectionData[]) => {
      return (
        content.filter(
          item =>
            !item.visibility || item.visibility === customerSegment || item.visibility === 'all'
        ) || []
      )
    },
    [customerSegment]
  )

  useEffect(() => {
    const draft = window.location.search.includes('draft=true')

    const marketingZoneContent = parseSegmentContent(marketingZone)
    const data = marketingZoneContent.map(item => parseCmsData<CardSectionData>(item))

    if (draft) {
      loadData(draft).then(data => {
        const marketingZoneContent = parseSegmentContent(data.attributes.marketingZone)
        const _data = marketingZoneContent.map(item => parseCmsData<CardSectionData>(item))

        setData(_data)
      })
    } else {
      setData(data)
    }
  }, [])

  if (!currentData.length) {
    return null
  }

  return <CardSection data={currentData[0]} />
}

/** @jsx jsx */
import { Box, jsx, Grid } from 'theme-ui'
import { FC } from 'react'
import { useLocation } from '@reach/router'
import { space } from '~/gatsby-plugin-theme-ui'
import AccountAside from '~/components/account-layout/account-aside'
import useMediaQuery from '~/hooks/use-media-query'
import AccountAsideMobileScroll from './account.aside.mobile-scroll'

type Props = {
  title?: string
}

const AccountLayout: FC<Props> = ({ children }) => {
  const { pathname } = useLocation()
  const asideWidth = 250
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  return (
    <Box
      sx={{
        pb: [space.xl2, null, 203],
        px: [space.md, null, 0],
        position: 'relative',
        h3: {
          color: '#505858',
        },
      }}
    >
      <Grid columns={[1, null, null, '250px 1fr']}>
        {isMobile ? (
          <AccountAsideMobileScroll pathname={pathname} />
        ) : (
          <AccountAside asideWidth={asideWidth} pathname={pathname} />
        )}
        <Box>{children}</Box>
      </Grid>
    </Box>
  ) as JSX.Element
}

export default AccountLayout

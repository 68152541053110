/** @jsx jsx */
import { Container, jsx, Box, Flex, Text, Grid } from 'theme-ui'
import { FC, useCallback, useState } from 'react'
import { Button } from '~/components/button'
import urls from '~/common/urls'
import { CustomerType } from '~/@types/models'
import { useCustomerContext } from '~/context/customer-context'
import { path } from 'ramda'
import { space } from '~/gatsby-plugin-theme-ui'
import Pencil from '~/components/icons/pencil'
import TrashCan from '~/components/icons/trashCan'
import VisaImg from '~/images/Visa.svg'
import MasterCardImg from '~/images/MasterCard.svg'

type Props = {}

const mapAddresses = (customer: CustomerType) => {
  const defaultAddressId = path(['defaultAddress', 'id'], customer)
  return customer.addresses.edges.map(edge => ({
    ...edge.node,
    isDefault: edge.node.id === defaultAddressId,
  }))
}

const AccountPaymentMethod: FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { customer, updateDefaultAddress, deleteAddress } = useCustomerContext()
  const addresses = mapAddresses(customer as CustomerType)

  const handleUpdateDefaultAddress = useCallback(async (addressId: string) => {
    setLoading(true)
    await updateDefaultAddress(addressId)
    setLoading(false)
  }, [])

  const handleDeleteAddress = useCallback(async (addressId: string) => {
    setLoading(true)
    await deleteAddress(addressId)
    setLoading(false)
  }, [])

  return (
    <Box>
      <Grid columns={[1, null, null, 2]} sx={{}} gap={space.gridGap3}>
        <Flex>
          <h1
            sx={{
              fontFamily: 'Cormorant Garamond, Times New Roman, serif',
              fontWeight: 400,
              fontSize: '40px',
              mt: [0],
              lineHeight: '48.44px',
              color: '#2b2b2b',
              mb: [0],
              marginRight: ['10px', '20px'],
            }}
          >
            PAYMENT METHOD
          </h1>
        </Flex>
        <Flex>
          <Button
            label="Add New"
            as="link"
            to={urls.account.accountPaymentMethodsAdd}
            variant="primary"
            sxProps={{
              width: '100%',
              textDecoration: 'none',
            }}
          />
        </Flex>
      </Grid>
      {loading ? (
        <Container sx={{ mt: [40] }} variant="container">
          Loading...
        </Container>
      ) : (
        <Container sx={{ mt: [40] }} variant="container">
          <Grid
            columns={[1, null, null, 2]}
            sx={{
              mb: [93],
            }}
            gap={space.gridGap3}
          >
            <Box
              sx={{
                border: 'solid #C8C8C8 1px',
                padding: '20px',
                paddingBottom: '5px',
                ':not(:last-child)': {},
              }}
            >
              <Grid columns={[1, null, null, 2]} sx={{}} gap={space.gridGap3}>
                <img
                  src={VisaImg}
                  alt=""
                  sx={{
                    height: '27px',
                    width: '83px',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                  }}
                />
                <Box
                  as={Text}
                  sx={{
                    fontFamily: 'Helvetica, Times New Roman, serif',
                    padding: '5px 10px 5px 10px',
                    textTransform: 'uppercase',
                    color: '#FFFFFF',
                    fontWeight: 400,
                    fontSize: [2],
                    background: '#051826',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                >
                  DEFAULT
                </Box>
              </Grid>
              <Flex
                sx={{
                  flexDirection: 'column',
                  boxSizing: 'border-box',
                }}
              >
                <h2
                  sx={{
                    fontFamily: 'Helvetica, Times New Roman, serif',
                    fontWeight: 400,
                    fontSize: '20px',
                    lineHeight: '32px',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                    color: '#2b2b2b',
                  }}
                >
                  CARD NAME
                </h2>
                <Text
                  sx={{
                    fontFamily: 'Helvetica, Times New Roman, serif',
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: '20px',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                    color: '#2b2b2b',
                  }}
                >
                  *******************0000
                </Text>
              </Flex>
              <Flex
                sx={{
                  borderTop: 'solid #C8C8C8 1px',
                  paddingTop: '2px',
                  minHeight: '45px',
                  'a, button': {
                    mr: ['12px', '24px'],
                  },
                }}
              >
                <button
                  sx={{
                    display: 'inline-block',
                    minWidth: 'auto',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                  }}
                  // to={`${urls.account.accountAddressesEdit}${address.id}`}
                >
                  <Pencil color="#2b2b2b" width="24px" height="24px" />
                </button>
                <button
                  sx={{
                    display: 'inline-block',
                    minWidth: 'auto',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                  }}
                  // onClick={() => {
                  //   handleDeleteAddress(address.id)
                  // }}
                >
                  <TrashCan color="#2b2b2b" width="24px" height="24px" />
                </button>
                <Button
                  label="Make default"
                  variant="buttons.primary"
                  sxProps={{
                    display: 'none',
                    minWidth: 'auto',
                    background: 'white',
                    color: '#051826',
                  }}
                />
              </Flex>
            </Box>
            <Box
              sx={{
                border: 'solid #C8C8C8 1px',
                padding: '20px',
                paddingBottom: '5px',
                ':not(:last-child)': {},
              }}
            >
              <Grid columns={[1, null, null, 2]} sx={{}} gap={space.gridGap3}>
                <img
                  src={MasterCardImg}
                  alt=""
                  sx={{
                    height: '27px',
                    width: 'auto',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                  }}
                />
                {/* <Box
                        as={Text}
                        sx={{
                          fontFamily: 'Helvetica, Times New Roman, serif',
                          padding: '5px 10px 5px 10px',
                          textTransform: 'uppercase',
                          color: '#FFFFFF',
                          fontWeight: 400,
                          fontSize: [2],
                          background: '#051826',
                          textAlign: 'center',
                          margin: 'auto'
                        }}
                      >
                        DEFAULT
                      </Box> */}
              </Grid>
              <Flex
                sx={{
                  flexDirection: 'column',
                  boxSizing: 'border-box',
                }}
              >
                <h2
                  sx={{
                    fontFamily: 'Helvetica, Times New Roman, serif',
                    fontWeight: 400,
                    fontSize: '20px',
                    lineHeight: '32px',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                    color: '#2b2b2b',
                  }}
                >
                  CARD NAME
                </h2>
                <Text
                  sx={{
                    fontFamily: 'Helvetica, Times New Roman, serif',
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: '20px',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                    color: '#2b2b2b',
                  }}
                >
                  *******************0000
                </Text>
              </Flex>
              <Flex
                sx={{
                  borderTop: 'solid #C8C8C8 1px',
                  paddingTop: '2px',
                  minHeight: '45px',
                  justifyContent: 'space-between',
                  'a, button': {
                    mr: ['12px', '24px'],
                  },
                }}
              >
                <Box sx={{ alignSelf: 'center' }}>
                  <button
                    sx={{
                      display: 'inline-block',
                      minWidth: 'auto',
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer',
                    }}
                    // to={`${urls.account.accountAddressesEdit}${address.id}`}
                  >
                    <Pencil color="#2b2b2b" width="24px" height="24px" />
                  </button>
                  <button
                    sx={{
                      display: 'inline-block',
                      minWidth: 'auto',
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer',
                    }}
                    // onClick={() => {
                    //   handleDeleteAddress(address.id)
                    // }}
                  >
                    <TrashCan color="#2b2b2b" width="24px" height="24px" />
                  </button>
                </Box>
                <Box>
                  <Button
                    label="Make default"
                    variant="buttons.primary"
                    sxProps={{
                      display: 'inline-block',
                      minWidth: 'auto',
                      background: 'white',
                      color: '#051826',
                    }}
                  />
                </Box>
              </Flex>
            </Box>
          </Grid>
          {/* <Box
            sx={{
              mb: [93],
            }}
          > */}

          {/* </Box> */}
        </Container>
      )}
    </Box>
  )
}

export default AccountPaymentMethod

/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import { navigate } from 'gatsby'
import { useCustomerContext } from '~/context/customer-context'
import urls from '~/common/urls'

type Props = {
  path: string
  component: FC<any>
}

const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useCustomerContext()

  if (!isLoggedIn) {
    navigate(urls.account.signIn)
    return null
  }
  return <Component {...rest} />
}

export default PrivateRoute

import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import FormField2 from '~/components/form-field2'
import { object, string } from 'yup'
import useFormSubmission from '~/hooks/use-form-submission'
import { Box } from 'theme-ui'
import { Button } from '~/components/button'
import { useCustomerContext } from '~/context/customer-context'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  initialValues?: FormValues
}

type FormValues = {
  firstName: string
  lastName: string
  email: string
}

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
})

const ModalUpload: React.FC<Props> = ({ isOpen, onRequestClose, initialValues = {} }) => {
  const { updateCustomer } = useCustomerContext()

  const [isNext, setIsNext] = useState(false)

  useEffect(() => {
    setIsNext(false)
  }, [onRequestClose])

  const submitCallback = async (values: FormValues) => {
    await updateCustomer({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    })
  }

  const handleNext = () => {
    setIsNext(true)
  }

  const { onSubmit, isLoading, serverError, isRead } = useFormSubmission<FormValues, any>(
    submitCallback
  )
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Minimal Modal Example"
      style={{
        overlay: {},
        content: {
          color: 'lightsteelblue',
          position: 'fixed',
          top: '30%',
          left: '60%',
          backgroundColor: 'white',
          border: '1px solid #C8C8C8',
          width: '494px',
          height: '494px',
        },
      }}
    >
      <h2
        style={{
          fontFamily: 'Cormorant Garamond, Times New Roman, serif',
          fontWeight: 400,
          fontSize: '30px',
          color: '#2b2b2b',
          marginTop: '10px',
        }}
      >
        UPLOAD DOCUMENTS
      </h2>
      <Box>
        <FormField2 as="select" name="current password" label="DOCUMENT TYPE" />
        <Box
          sx={{
            width: '100%',
            border: '1px solid #1434CB',
            padding: '10px',
            textAlign: 'center',
            backgroundColor: 'white',
            color: '#1434CB',
            borderStyle: 'dotted',
            height: '118px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'underline',
          }}
        >
          Upload documents
        </Box>
        <Button
          label="next"
          sxProps={{
            mt: [10],
            width: '100%',
          }}
          type="submit"
          hasArrow={false}
          onClick={handleNext}
        />
        <Button
          label="Cancel"
          sxProps={{
            mt: [32],
            width: '100%',
            backgroundColor: 'white',
            border: 'none',
            color: '#051826',
            textDecoration: 'underline',
          }}
          hasArrow={false}
          onClick={onRequestClose}
        />
      </Box>
      {/* <button onClick={onRequestClose}>Cancel</button> */}
    </ReactModal>
  )
}

export default ModalUpload

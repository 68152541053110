/** @jsx jsx */
import { Box, Grid, jsx, SxStyleProp, Flex } from 'theme-ui'
import React, { FC, useMemo, useState } from 'react'
import { Button } from '~/components/button'
import urls from '~/common/urls'
import { space } from '~/gatsby-plugin-theme-ui'
import { formatDate } from '~/utils/format'
import { Search } from '~/components/icons/search'
import Pagination from '~/components/paginator/Pagination'
import MagnifierPaper from '../icons/magnifierPaper'
import { Link } from '~/componentsV2/linkPrev/LinkPrev'
import { CustomerOrder } from '~/@types/models'
import { H3, H5 } from '~/styled/components/typography'
import { DashboardEmptyState } from '../account-layout/components/sections'

// let PageSize = 5;

type BodyBoxProps = {
  sxProps?: SxStyleProp
}

const HEADERS = ['DATE', 'ORDER', 'ITEMS', 'TOTAL', 'STATUS']

const BodyBox: FC<BodyBoxProps> = ({ children, sxProps }) => (
  <Box
    sx={{
      color: 'txt',
      fontSize: [3],
      ...sxProps,
    }}
  >
    {children}
  </Box>
)

const HeaderRow: FC = ({ children }) => (
  <Box
    sx={{
      // color: 'text',
      color: '#2b2b2b',
      fontSize: [3],
      mb: [18],
      fontWeight: 400,
      // textTransform: 'uppercase',
    }}
  >
    {children}
  </Box>
)

const OrderRow: FC = order => {
  const unformatedPrice = order.totalPrice.amount
  const formatedPrice = Number(unformatedPrice).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const prefix = 'gid://shopify/Order/'
  const orderOnlyId = order.id.substring(prefix.length)

  const orderEdges = order.lineItems.edges
  const orderItems: string[] = []
  orderEdges.map((element: any) => {
    return orderItems.push(element.node.title)
  })
  const countOfItems = order.lineItems.edges.length

  return (
    <React.Fragment>
      <BodyBox>{formatDate(order.processedAt)}</BodyBox>
      <BodyBox>
        <Link
          to={`${urls.account.accountOrders}/${orderOnlyId}`}
          sx={{
            textDecoration: 'underline',
            color: '#2b2b2b',
            textUnderlineOffset: '3px',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          {/* <MagnifierPaper /> */}
          {order.orderNumber}
        </Link>
      </BodyBox>
      <BodyBox>
        <Box as="span">
          {/* {String(order.lineItems.edges)} */}
          {orderItems[0]}
        </Box>
      </BodyBox>
      <BodyBox>
        $ {formatedPrice}
        {/* for {countOfItems} {countOfItems > 1 ? 'items' : 'item'} */}
      </BodyBox>
      <BodyBox>
        <Box
          as="span"
          variant="variants.firstLetterToUppercase"
          sx={{ textTransform: 'uppercase' }}
        >
          {String(order.fulfillmentStatus)}
        </Box>
      </BodyBox>
    </React.Fragment>
  )
}

type Props = {
  orders: CustomerOrder[]
  isOverview?: boolean
  PageSize?: number
}

const OrdersList: FC<Props> = ({ orders = [], isOverview = false, PageSize = 5 }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return orders.slice(firstPageIndex, lastPageIndex)
  }, [currentPage])

  const gridPercentage = [5, '10% 12% 44% 14% 12%']

  return (
    <div>
      {!isOverview && (
        <Flex>
          <h1
            sx={{
              fontFamily: 'Cormorant Garamond, Times New Roman, serif',
              fontWeight: 400,
              fontSize: '2rem',
              mt: [0],
              lineHeight: '48.44px',
              color: '#2b2b2b',
              mb: [36],
              marginRight: ['10px', '20px'],
            }}
          >
            ORDERS
          </h1>
        </Flex>
      )}
      <Grid
        columns={gridPercentage}
        sx={{
          // borderBottom: t => `1px solid ${t.colors.strokes}`,
          // mb: [12],
          p: '10px 0',
        }}
        gap={space.md}
      >
        {HEADERS.map(item => (
          <HeaderRow>{item}</HeaderRow>
        ))}
      </Grid>
      {currentTableData.map(order => (
        <Grid
          key={order.id}
          columns={gridPercentage}
          gap={space.md}
          sx={{
            alignItems: 'flex-start',
            mb: 0,
            p: '10px 0',
            // backgroundColor: 'white',
            // '&:nth-child(odd)': {
            //   backgroundColor: 'transparent',
            // },
          }}
        >
          <OrderRow {...order} />
        </Grid>
      ))}
      {currentTableData.length === 0 && (
        <DashboardEmptyState>Currently no orders</DashboardEmptyState>
      )}
      {!isOverview && (
        <Grid
          columns={[2]}
          sx={{
            borderTop: t => `1px solid #E6E6E6`,
            mb: [12],
            pt: [16],
          }}
        >
          <span
            sx={{
              fontSize: '14px',
            }}
          >
            Showing {currentTableData.length} items of {orders.length}
          </span>
          <Pagination
            className="pagination-bar justify-right"
            currentPage={currentPage}
            totalCount={orders.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        </Grid>
      )}
    </div>
  )
}

export default OrdersList

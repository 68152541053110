/** @jsx jsx */
import { Container, jsx } from 'theme-ui'
import { FC } from 'react'
import AddressForm2 from '~/containers/address-form2'
import { useCustomerContext } from '~/context/customer-context'

type Props = {
  id: string
}

const AccountAddressesEdit: FC<Props> = ({ id }) => {
  const { customer } = useCustomerContext()

  const address = customer.addresses.edges.map(edge => edge.node).find(address => address.id === id)
  // console.log(address);
  return (
    <Container variant="mid">
      <AddressForm2
        initialValues={{
          ...address,
        }}
        isEdit
      />
    </Container>
  )
}

export default AccountAddressesEdit

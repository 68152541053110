/** @jsx jsx */
import { Box, jsx, Grid, Text } from 'theme-ui'
import { FC } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import ErrorFocus from '~/components/error-focus'
import ErrorMessage from '~/components/error-message'
import { Button } from '~/components/button'
import { Maybe } from '~/@types/models'
import { Checkbox } from '~/components/checkbox'
import { navigate } from 'gatsby'
import urls from '~/common/urls'

type Props = {
  formProps: object
  // buttonLabel: string
  isLoading?: boolean
  isRead?: boolean
  serverError?: Maybe<string>
  isDefault?: boolean
  setIsRead?: any
  isCancel?: boolean
  setIsCancel?: any
  isNew?: boolean
}

const FormReadOnly: FC<Props> = ({
  children,
  formProps,
  setIsRead,
  serverError = null,
  isLoading = false,
  // buttonLabel,
  isRead = false,
  setIsCancel,
  isCancel = false,
  isDefault = false,
  isNew = false,
}) => {
  const handleCancel = () => {
    // console.log('tries', isNew)
    if (isNew) {
      return navigate(urls.account.accountAddresses)
    }
    setIsCancel(true)
    // if (!isRead) {
    //   setIsRead(true) // Set isRead to true when cancel button is clicked
    // }
  }
  return (
    <Formik enableReinitialize {...formProps}>
      <FormikForm>
        <ErrorFocus />
        {children}
        <Grid
          columns={[1, '1fr 2fr']}
          sx={{
            display: !isRead ? 'grid' : 'none',
            width: '100%',
            margin: 'auto',
          }}
        >
          <Box sx={{ alignSelf: 'end' }}>
            {isDefault && (
              <Box
                as={Text}
                sx={{
                  fontFamily: 'Helvetica, Times New Roman, serif',
                  padding: '5px 15px 5px 15px',
                  textTransform: 'uppercase',
                  color: '#FFFFFF',
                  fontWeight: 400,
                  fontSize: [2],
                  background: '#585858',
                  textAlign: 'center',
                }}
              >
                DEFAULT
              </Box>
            )}
          </Box>

          <Box sx={{ justifySelf: 'right' }}>
            <Button
              label={isLoading ? '...' : 'Cancel'}
              disabled={isLoading}
              sxProps={{
                minWidth: '125px',
                mt: [20],
                backgroundColor: '#f8f8f8',
                color: '#16283e',
                mr: [10],
                border: 'none',
              }}
              type="submit"
              onClick={handleCancel}
              hasArrow={false}
            />
            <Button
              label={isLoading ? '...' : 'Save'}
              disabled={isLoading}
              sxProps={{
                minWidth: '125px',
                mt: [20],
                ml: [10],
                border: 'none',
                backgroundColor: '#16283e',
              }}
              type="submit"
              hasArrow={false}
            />
          </Box>
        </Grid>
        {serverError && <ErrorMessage error={serverError} data-cy="server-error" />}
      </FormikForm>
    </Formik>
  )
}

export default FormReadOnly

import React, { useCallback, useEffect } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import CardSection, { CardSectionData } from '~/componentsV2/pageBuilder/sections/CardSection'
import { parsePage } from '~/componentsV2/pageBuilder/utils'
import { useIdentifierModal } from '~/context/identifier-modal'

import { cmsEnv } from '~/common/cms'
import BlockTextRender, {
  BlockTextRenderProps,
} from '~/componentsV2/pageBuilder/components/BlockTextRender'
import { BlocksContent } from '@strapi/blocks-react-renderer'
import { Wrapper } from './styles'

interface QueryResult {
  dashboardCms: {
    marketingZone: CardSectionData[]
    infoRetail: BlocksContent
    infoTrade: BlocksContent
  }
}

const dashboardQuery = graphql`
  query {
    dashboardCms {
      marketingZone
      infoRetail
      infoTrade
    }
  }
`

export const loadData = async (isDraft: boolean): Promise<any> => {
  const res = await fetch(
    `${cmsEnv.endpoint}/dashboard?populate=deep${isDraft ? '&draft=true' : ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${cmsEnv.token}`,
      },
    }
  )
  return (await res.json()).data
}

export const InforArea = () => {
  const { dashboardCms } = useStaticQuery<QueryResult>(dashboardQuery)
  const { customerSegment } = useIdentifierModal()

  const [currentData, setData] = React.useState<BlocksContent>()

  const parseSegmentContent = useCallback(
    (data?: QueryResult['dashboardCms']) => {
      const { infoRetail, infoTrade } = data || dashboardCms
      if (customerSegment === 'trade' && infoTrade) {
        return infoTrade
      }

      return infoRetail
    },
    [customerSegment, dashboardCms]
  )

  useEffect(() => {
    const draft = window.location.search.includes('draft=true')

    const content = parseSegmentContent()

    if (draft) {
      loadData(draft).then(data => {
        const content = parseSegmentContent(data.attributes.dashboardCms)
        setData(content)
      })
    } else {
      setData(content)
    }
  }, [])

  if (!currentData) {
    return null
  }

  return (
    <Wrapper>
      <BlockTextRender content={currentData} />
    </Wrapper>
  )
}

import React from 'react'
import { SVGComponentProps } from './types'

const MagnifierPaper: SVGComponentProps = ({ ...rest }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_429_2265)">
      <path
        d="M6.00012 6V4.5H12.0001V6H6.00012ZM3.75012 16.5C3.55121 16.5 3.36044 16.421 3.21979 16.2803C3.07914 16.1397 3.00012 15.9489 3.00012 15.75V2.25C3.00012 2.05109 3.07914 1.86032 3.21979 1.71967C3.36044 1.57902 3.55121 1.5 3.75012 1.5H15.0001V8.625C15.557 8.85939 16.0657 9.19507 16.5001 9.615V0H3.75012C3.15338 0 2.58109 0.237053 2.15913 0.65901C1.73718 1.08097 1.50012 1.65326 1.50012 2.25V15.75C1.50012 16.3467 1.73718 16.919 2.15913 17.341C2.58109 17.7629 3.15338 18 3.75012 18H13.1251C12.4692 17.9999 11.8201 17.867 11.217 17.6092C10.6138 17.3514 10.0692 16.9741 9.61587 16.5H3.75012ZM16.9074 17.9678L14.9146 15.975C14.38 16.3162 13.7593 16.4983 13.1251 16.5C12.4576 16.5 11.8051 16.3021 11.2501 15.9312C10.6951 15.5604 10.2625 15.0333 10.007 14.4166C9.75158 13.7999 9.68475 13.1213 9.81497 12.4666C9.9452 11.8119 10.2666 11.2105 10.7386 10.7385C11.2106 10.2665 11.812 9.94507 12.4667 9.81485C13.1214 9.68462 13.8 9.75146 14.4167 10.0069C15.0334 10.2624 15.5605 10.6949 15.9313 11.25C16.3022 11.805 16.5001 12.4575 16.5001 13.125C16.4984 13.7592 16.3163 14.3799 15.9751 14.9145L17.9679 16.9072L16.9074 17.9678ZM13.1251 15C13.496 15 13.8585 14.89 14.1668 14.684C14.4752 14.478 14.7155 14.1851 14.8574 13.8425C14.9993 13.4999 15.0364 13.1229 14.9641 12.7592C14.8917 12.3955 14.7132 12.0614 14.4509 11.7992C14.1887 11.537 13.8546 11.3584 13.4909 11.286C13.1272 11.2137 12.7502 11.2508 12.4076 11.3927C12.065 11.5346 11.7721 11.775 11.5661 12.0833C11.3601 12.3916 11.2501 12.7542 11.2501 13.125C11.2501 13.6223 11.4477 14.0992 11.7993 14.4508C12.1509 14.8025 12.6278 15 13.1251 15Z"
        fill="#051826"
      />
    </g>
    <defs>
      <clipPath id="clip0_429_2265">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default MagnifierPaper

/** @jsx jsx */
import { Container, jsx, Box, Flex } from 'theme-ui'
import { FC, useEffect, useState } from 'react'
import AccountLayout from '~/components/account-layout'
import urls from '~/common/urls'
import FormReadOnly from '~/components/form-read-only'
import FormField from '~/components/form-field'
import FormRow from '~/components/form-row'
import useFormSubmission from '~/hooks/use-form-submission'
import { useCustomerContext } from '~/context/customer-context'
import { navigate } from 'gatsby'
import NotificationModal from '../notificationModal'
import { axiosRequests } from '~/utils/axios'

type Props = {
  initialValues?: FormValues
  id: string
}

type FormValues = {
  projectName: string
}

const AccountPortfoliosEdit: FC<Props> = ({ id }) => {
  const AxiosFunctions = axiosRequests()
  const [loading, setLoading] = useState<boolean>(false)
  const options: readonly string[] = ['PORTFOLIOS NAME']
  const { customer, createWishlist } = useCustomerContext()
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const [toastText, settoastText] = useState('')
  const [wishlistName, setwishlistName] = useState('')
  const [regid, setRegid] = useState('')
  const [sessionid, setSessionid] = useState('')
  const [listName, setListName] = useState('')
  const [oldList, setOldList] = useState()
  const [OldListName, setOldListName] = useState()
  const [isCancel, setIsCancel] = useState(false)
  const [ResponseData, setResponseData] = useState()

  function getSubstringBeforeQuestionMark(url) {
    if (!!url) {
      const questionMarkIndex = url.indexOf('?')
      return questionMarkIndex !== -1 ? url.slice(0, questionMarkIndex) : url
    }
  }

  // const renameWishlist = async newListName => {
  //   if (customer) {
  //     const pid = encodeURIComponent('75tILgSNu5MHwsfJWBXcldxb5CHw7WyuWqyOut7fKKw=')
  //     const dataLists = qs.stringify({
  //       sessionid: sessionid,
  //       regid: regid,
  //       lid: id.slice(1),
  //       lname: newListName,
  //     })
  //     const configList = {
  //       method: 'post',
  //       url: `https://main--timely-rabanadas-cedbc8.netlify.app/.netlify/functions/cors-proxy/https://swymstore-v3premium-01.swymrelay.com/api/v3/lists/update-ctx?pid=${pid}`,
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //         Accept: 'application/json',
  //         'user-agent': 'webApp',
  //       },
  //       data: dataLists,
  //     }
  //     await axios(configList).then(function (response) {
  //       return navigate(urls.account.accountPortfolios)
  //     })
  //   }
  // }

  const copyToWishlist = async (listId, listName, productId, variantId, productVarianUrl) => {
    const productHandle = getSubstringBeforeQuestionMark(productVarianUrl)
    await AxiosFunctions.copyToWishlist(
      listId,
      listName,
      productId,
      sessionid,
      regid,
      variantId,
      productHandle
    )
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        return error
      })
  }

  const removeWishlist = async () => {
    await AxiosFunctions.removeWishlist(sessionid, regid, id.slice(1))
      .then(function (response) {
        settoastText('Renamed project')
        setisSuccesful(true)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
          return navigate(urls.account.accountPortfolios)
        }, 2200)
      })
      .catch(function () {
        setwishlistName(listName)
        settoastText('Could not rename the project')
        setisSuccesful(false)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
        }, 2200)
      })
  }

  const createWishlists = async listName => {
    await AxiosFunctions.createWishlists(listName, ResponseData)
      .then(function (response) {
        setOldListName(listName)
        if (!!oldList.listcontents && oldList.listcontents.length > 0) {
          oldList.listcontents.map(product => {
            try {
              copyToWishlist(
                response.data.lid,
                response.data.lname,
                product.empi,
                product.epi,
                product.du
              )
            } catch (error) {
              return ''
            }
            return ''
          })
          removeWishlist()
        } else {
          removeWishlist()
        }
      })
      .catch(function (error) {
        settoastText('Could not rename project')
        setisSuccesful(false)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
        }, 3200)
        return error
      })
  }

  const submitCallback = async (values: FormValues) => {
    if (isCancel) {
      setIsCancel(false)
      return navigate(urls.account.accountPortfolios)
    }
    const requestValues = {
      projectName: values.projectName,
    }
    if (customer) {
      await createWishlists(requestValues.projectName)
    }
  }

  const getList = async () => {
    await AxiosFunctions.getList(id, sessionid, regid).then(function (response) {
      setOldListName(response.data.data.lname)
      setOldList(response.data.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (sessionid !== '' && regid !== '') {
      getList()
    }
  }, [regid, sessionid])

  const genRegid = async () => {
    await AxiosFunctions.genRegId(customer?.email)
      .then(function (response) {
        setRegid(response.data.regid)
        setSessionid(response.data.sessionid)
        setResponseData(response)
      })
      .catch(function () {
        // console.log(error);
      })
  }

  useEffect(() => {
    genRegid()
  }, [])

  const openToast = async (copyBoolean: boolean) => {
    settoastlIsOpen(true)
  }

  const closeToast = () => {
    settoastlIsOpen(false)
  }

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(submitCallback)

  return (
    <Container variant="container">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Flex>
          <h1
            sx={{
              fontFamily: 'Cormorant Garamond, Times New Roman, serif',
              fontWeight: 400,
              fontSize: '40px',
              mt: [0],
              lineHeight: '48.44px',
              color: '#2b2b2b',
              mb: [30],
              marginRight: ['10px', '20px'],
            }}
          >
            RENAME PROJECT
          </h1>
        </Flex>
      </Box>
      <FormReadOnly
        formProps={{
          onSubmit,
          initialValues: { projectName: OldListName },
        }}
        isLoading={isLoading}
        serverError={serverError}
        setIsCancel={setIsCancel}
      >
        <FormRow columns={[1, 2]}>
          <FormField name="projectName" label="Project Name" />
        </FormRow>
      </FormReadOnly>
      {toastlIsOpen && (
        <Box>
          <NotificationModal
            toastText={toastText}
            wishlistName={wishlistName}
            isFromModal={true}
            isSuccesful={isSuccesful}
            isMobile={false}
            isOpen={toastlIsOpen}
            onRequestClose={closeToast}
          />
        </Box>
      )}
    </Container>
  )
}

export default AccountPortfoliosEdit

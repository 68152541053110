/** @jsx jsx */
import { Container, jsx, Box, Flex, Text, Grid, Card, Image } from 'theme-ui'
import { FC, useEffect, useRef, useState } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'
import dropDown from '~/images/cirra-placeholder.png'
import { useCustomerContext } from '~/context/customer-context'
import { getProductByID } from '~/graphql/getters'
import { useProductVariantContext } from '~/containers/product-detail/product-variant-context'
import { ColorBoxV2 } from '~/componentsV2/colors/colorBox'
import { AddToCartButton } from '~/components/add-to-card-button'

type Props = {}

const AccountPortfoliosShared: FC<Props> = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const decodedData = urlParams.get('data') ? JSON.parse(atob(urlParams.get('data'))) : ''

  // console.log(decodedData);
  const ids = decodedData.productsIds || []
  const vIds = decodedData.variantIds || []
  const listName = decodedData.listName || ''
  const sharedUserName = decodedData.userName || ''
  let hasProducts = false

  if (ids.length > 0) {
    hasProducts = true
  }

  const { auth } = useCustomerContext()
  const [loading, setLoading] = useState<boolean>(true)

  const [imageSources, setImageSources] = useState({})
  const [productsArray, setproductsArray] = useState({})
  const [variantsImageSources, setvariantsImageSources] = useState({})
  const [variantsIds, setvariantsIds] = useState({})
  const [selectedVariantNodeArray, setSelectedVariantNodeArray] = useState({})

  const { product, variant } = useProductVariantContext()
  const [isSelectOpen, setisSelectOpen] = useState(false)
  const menuRef = useRef()
  const handleMenuToggle = () => {
    setisSelectOpen(!isSelectOpen)
  }
  const handleOutsideClick = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setisSelectOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const options: readonly string[] = ['PORTFOLIOS NAME']

  useEffect(() => {
    if (hasProducts) {
      const fetchProductData = async listEntry => {
        try {
          const prodId = 'gid://shopify/Product/' + listEntry
          const data = await getProductByID(prodId)
          const prodImageSrc = data.images.edges[0].node.src.toString()
          const prodVariantImageSrc = data.variants.edges[0].node.image.srcSmall.toString()
          // Update the listEntry with prodImageSrc
          setproductsArray(product => ({
            ...product,
            [data.title]: data,
          }))
          setImageSources(prevImageSources => ({
            ...prevImageSources,
            [data.title]: prodImageSrc,
          }))
          setvariantsImageSources(prevVariantSources => ({
            ...prevVariantSources,
            [data.title]: prodVariantImageSrc,
          }))
          for (let index = 0; index < ids.length; index++) {
            const productVariant = `gid://shopify/ProductVariant/${vIds[index]}`
            const selectedVariant = data?.variants.edges.find(
              variant => variant.node.id === productVariant
            )
            const selectedVariantNode = selectedVariant?.node
            if (!!selectedVariantNode) {
              selectedVariantNode.shopifyId = selectedVariantNode?.id
              selectedVariantNode.shopifyId2 = vIds[index].toString()
              setSelectedVariantNodeArray(selVariantNode => ({
                ...selVariantNode,
                [data.title]: selectedVariantNode,
              }))
            }
          }
        } catch (error) {
          console.error('Error fetching product data:', error)
        }
      }

      const fetchAllProductData = async () => {
        setLoading(true)
        if (!hasProducts) {
          return
        }
        await Promise.all(ids.map(fetchProductData))
        setLoading(false)
      }
      fetchAllProductData()
    }
  }, [])

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  if (!decodedData) {
    return (
      <Box>
        <Text>Portifolio not found</Text>
      </Box>
    )
  }

  return (
    <Box>
      <Container variant="container">
        <Box sx={{ display: 'relative' }}>
          <span>
            <Text sx={{ color: '#808080', fontSize: '13.33px' }}>Project by {sharedUserName}</Text>
          </span>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <h1
              sx={{
                fontFamily: 'Cormorant Garamond, Times New Roman, serif',
                fontWeight: 400,
                fontSize: '33.18px',
                mt: [0],
                lineHeight: '48.44px',
                color: '#2b2b2b',
                mb: [0],
                cursor: 'pointer',
                textTransform: 'uppercase',
              }}
              onClick={handleMenuToggle}
            >
              {listName === 'default' ? 'my favorites' : listName}
            </h1>
            <Text sx={{ fontSize: '16px', fontWeight: '400' }}>
              {Object.values(productsArray)?.length} product
              {Object.values(productsArray)?.length > 1 ? 's' : ''}
            </Text>
          </Box>
          <Flex></Flex>
        </Box>
        <Grid columns={[2, null, null, 3]} sx={{}} gap={space.gridGap3}>
          {!loading &&
            Object.values(productsArray).map((list, index) => {
              const prodId = 'Shopify__Product__gid://shopify/Product/' + ids[index]
              //Algolia params
              const _indexName = 'products'

              let queryID = ''
              if (typeof window !== 'undefined') {
                queryID = localStorage.getItem('queryID') ?? ''
              }
              const algoliaData = {
                index: _indexName,
                eventName: 'Product Added to Cart',
                userToken: auth?.accessToken ?? 'user-id',
                objectIDs: [prodId],
                queryID: queryID,
              }
              return (
                <Card sx={{ justifySelf: 'center' }}>
                  <Image
                    src={imageSources[list.title] ? imageSources[list.title] : dropDown}
                    sx={{
                      maxHeight: '200px',
                      alignContent: 'center',
                      width: 'fill-available',
                      objectFit: 'cover',
                    }}
                  />
                  <Box
                    sx={{
                      textAlign: 'end',
                      position: 'absolute',
                      top: '12vh',
                      marginLeft: '16vw',
                    }}
                  ></Box>
                  <Grid
                    columns={[1]}
                    gap={space.gridGap3}
                    sx={{
                      flexDirection: 'column',
                      boxSizing: 'border-box',
                      marginTop: '1em',
                      marginBottom: '1em',
                    }}
                  >
                    <Box>
                      <Text
                        sx={{
                          fontFamily: 'Cormorant Garamond',
                          fontSize: '24px',
                          fontWeight: 400,
                          lineHeight: '19px',
                          letterSpacing: '0em',
                          color: '#2b2b2b',
                          textTransform: 'uppercase',
                        }}
                      >
                        {list.title}
                      </Text>
                    </Box>
                    <Box
                      as="li"
                      sx={{
                        listStyle: 'none',
                        p: ['1px', '4px'],
                        width: [30, 60],
                        height: [30, 60],
                        mt: [20],
                      }}
                    >
                      <ColorBoxV2
                        value=""
                        src={
                          variantsImageSources[list.title]
                            ? variantsImageSources[list.title]
                            : dropDown
                        }
                        selectedValue=""
                      />
                    </Box>
                    <Box>
                      <Text
                        sx={{
                          fontSize: '18px',
                          fontWeight: 400,
                          lineHeight: '15px',
                          letterSpacing: '0em',
                          color: '#2b2b2b',
                        }}
                      >
                        ${selectedVariantNodeArray[list.title].price.amount}
                      </Text>
                    </Box>
                    <Box>
                      <AddToCartButton
                        title={list.title}
                        variant={selectedVariantNodeArray[list.title]}
                        initialQuantity={1}
                        sxProps={{
                          width: '100%',
                          height: '42px',
                        }}
                        label="Add to Cart"
                        algoliaData={algoliaData}
                      />
                    </Box>
                  </Grid>
                </Card>
              )
            })}
        </Grid>
      </Container>
    </Box>
  )
}

export default AccountPortfoliosShared

/** @jsx jsx */
import { Container, jsx, Flex, Box, Grid } from 'theme-ui'
import { FC } from 'react'
import AccountLayout from '~/components/account-layout'
import AddressForm2 from '~/containers/address-form2'
import FormRow from '~/components/form-row'
import FormReadOnly from '~/components/form-read-only'
import FormField from '~/components/form-field'
import VisaImg from '~/images/Visa.svg'
import { space } from '~/gatsby-plugin-theme-ui'

type Props = {}

const AccountPaymentMethodsAdd: FC<Props> = () => {
  return (
    <Box>
      <Container variant="container">
        <Grid columns={[1, 2]} sx={{}} gap={space.gridGap1}>
          <Flex>
            <h1
              sx={{
                fontFamily: 'Cormorant Garamond, Times New Roman, serif',
                fontWeight: 400,
                fontSize: '40px',
                mt: [0],
                lineHeight: '48.44px',
                color: '#2b2b2b',
                mb: [36],
                marginRight: ['10px', '20px'],
              }}
            >
              NEW PAYMENT METHOD
            </h1>
          </Flex>
          <Flex>
            <span style={{ fontSize: '12px', fontWeight: 400, lineHeight: '48.44px' }}>
              CARD DATA - BILLING ADDRESS
            </span>
          </Flex>
        </Grid>
        {/* <AddressForm2 /> */}
        <FormReadOnly formProps={{}}>
          <FormRow columns={[1, 3]}>
            <FormField name="firstName" label="CARD NUMBER" />
            <Box sx={{ mt: [32] }}>
              <img
                src={VisaImg}
                alt=""
                sx={{
                  padding: '3px',
                  height: '40px',
                  width: '83px',
                  marginTop: '3px',
                  marginBottom: '0',
                  border: '1px solid',
                }}
              />
            </Box>
          </FormRow>
          <FormRow columns={[1, 3]}>
            <FormField name="address2" label="CARDHOLDER" />
            <FormField name="country" label="DUE DATE" />
            <FormField name="country" label="CVC" />
          </FormRow>
        </FormReadOnly>
      </Container>
    </Box>
  )
}

export default AccountPaymentMethodsAdd

/** @jsx jsx */
import { jsx, Container, Box, Flex } from 'theme-ui'
import { FC } from 'react'
import { pick } from 'ramda'
import PersonalDataForm2 from '~/containers/personal-data-form-2'
import AccountLayout from '~/components/account-layout'
import { useCustomerContext } from '~/context/customer-context'

type Props = {}

const AccountProfile: FC<Props> = () => {
  const { customer } = useCustomerContext()

  return (
    <Container variant="container" sx={{ padding: '0' }}>
      <Box
        sx={{
          mb: [124],
        }}
      >
        <PersonalDataForm2
          initialValues={pick(['firstName', 'lastName', 'email', 'phone', 'metafields'], customer)}
        />
      </Box>
    </Container>
  )
}

export default AccountProfile

/** @jsx jsx **/
import { Box, Flex, jsx } from 'theme-ui'
import { FC } from 'react'
import RouteLink from '~/components/account-layout/route-link'
import { Link as GatsbyLink } from 'gatsby'
import urls from '~/common/urls'
import { ACCOUNT_ROUTES_MENU } from './constants/routeConfig'

type AccountAsideProps = {
  asideWidth: number
  pathname: string
}

const isCurrentLink = (pathname: string, base: string) => {
  return String(pathname).startsWith(base)
}

const AccountAside: FC<AccountAsideProps> = ({ pathname }) => {
  return (
    <Box as="aside">
      <Flex
        as="ul"
        sx={{
          listStyleType: 'none',
          flexDirection: 'column',
        }}
      >
        {ACCOUNT_ROUTES_MENU.map(link => (
          <RouteLink key={link.url} link={link} isCurrent={isCurrentLink(pathname, link.url)} />
        ))}
        <Box as="li">
          <GatsbyLink
            to={urls.account.logout}
            sx={{
              color: ' #505858',
              textDecoration: 'none',
              textTransform: 'uppercase',
              fontSize: [3],
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            data-cy="logout-link"
          >
            Logout
          </GatsbyLink>
        </Box>
      </Flex>
    </Box>
  )
}

export default AccountAside

export function formatPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) {
    return ''
  }
  if (phoneNumber.slice(0, 2) !== '+1') {
    return ''
  }
  const cleaned = phoneNumber.slice(2)
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/u)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return phoneNumber
}

/** @jsx jsx */
import { Container, jsx, Box, Flex, Grid } from 'theme-ui'
import { FC, useState } from 'react'
import urls from '~/common/urls'
import { CustomerType } from '~/@types/models'
import { useCustomerContext } from '~/context/customer-context'
import { path, pick } from 'ramda'
import { space } from '~/gatsby-plugin-theme-ui'
import AddressForm2 from '~/containers/address-form2'
import { DashboardPageTitle } from '~/components/account-layout/components/sections'

type Props = {}

const mapAddresses = (customer: CustomerType) => {
  const defaultAddressId = path(['defaultAddress', 'id'], customer)
  return customer.addresses.edges.map(edge => ({
    ...edge.node,
    isDefault: edge.node.id === defaultAddressId,
  }))
}

const AccountAddresses2: FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { customer, updateDefaultAddress, deleteAddress } = useCustomerContext()

  if (!customer) {
    return null
  }

  const addresses = mapAddresses(customer as CustomerType)

  return (
    <Box>
      <Container
        variant="container"
        sx={{ padding: '0', '& label': { textTransform: 'uppercase' } }}
      >
        <DashboardPageTitle
          title="ADDRESSES"
          link={urls.account.accountAddressesAdd}
          label="CREATE NEW"
          align="space-between"
        />
        {loading ? (
          <Box sx={{ mt: [40] }} variant="container">
            Loading...
          </Box>
        ) : (
          <Box sx={{ mt: [40] }}>
            <Grid
              columns={[1]}
              sx={{
                mb: [93],
              }}
              gap={space.gridGap3}
            >
              {addresses.map((address, index) => {
                return (
                  <Box
                    key={address.id}
                    sx={{
                      ':not(:last-child)': {
                        borderBottom: '1px solid',
                        borderColor: 'border',
                        paddingBottom: '60px',
                      },
                    }}
                  >
                    <AddressForm2
                      initialValues={pick(
                        [
                          'id',
                          'firstName',
                          'lastName',
                          'phone',
                          'address1',
                          'country',
                          'province',
                          'city',
                          'zip',
                          'company',
                          'address2',
                          'isDefault',
                        ],
                        address
                      )}
                      addNumber={index + 1}
                      isEdit={true}
                      isReadD={false}
                    />
                  </Box>
                )
              })}
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default AccountAddresses2

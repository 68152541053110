/** @jsx jsx */
import { Container, jsx, Text } from 'theme-ui'
import { FC, useEffect, useState } from 'react'
import Layout from '~/components/layout'
import { useCustomerContext } from '~/context/customer-context'
import { AccountRouter } from '~/components/account-layout/router'
import AccountLayout from '~/components/account-layout'

type Props = {}

const Account: FC<Props> = () => {
  const { fetchCustomer } = useCustomerContext()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCustomer()
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return (
      <Layout headerVariant="default" footer={false}>
        <Container
          sx={{
            height: '60vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            sx={{
              fontFamily: 'heading',
              fontSize: [9],
              fontWeight: [600],
            }}
          >
            Loading...
          </Text>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout headerVariant="default" footer={true}>
      <Container
        sx={{
          pt: [0, null, 30],
          pr: [0, null, 24],
          pl: [0, null, 24],
        }}
      >
        <AccountLayout>
          <AccountRouter />
        </AccountLayout>
      </Container>
    </Layout>
  )
}

export default Account

/** @jsx jsx */
import { jsx, Grid, GridProps } from 'theme-ui'
import { FC } from 'react'

type Props = GridProps

const FormRow: FC<Props> = ({ children, ...rest }) => (
  <Grid gap={21} {...rest}>
    {children}
  </Grid>
)

export default FormRow

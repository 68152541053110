/** @jsx jsx */
import { jsx, Box, Grid } from 'theme-ui'
import { FC } from 'react'
import { useDashboard } from '../context/dashboardContext'

import { space } from '~/gatsby-plugin-theme-ui'
import { DashboardProductCard } from './productCard'
import { H5 } from '~/styled/components/typography'
import { DashboardEmptyState } from './sections'

type Props = {
  cardProps: {
    showMenu?: boolean
    style?: React.CSSProperties
  }
}

export const DashboardSflGrid: FC<Props> = ({ cardProps }) => {
  const { sflLists, loading } = useDashboard()

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <p>Loading...</p>
      </Box>
    )
  }

  if (!sflLists?.items?.length) {
    return <DashboardEmptyState>No saved for later items</DashboardEmptyState>
  }

  return (
    <div>
      <Grid columns={[2, null, null, 3]} sx={{}} gap={space.gridGap3}>
        {sflLists?.items?.map((list, index) => {
          return (
            <DashboardProductCard
              key={index}
              listName={sflLists.list.lname}
              list={list}
              sfl={true}
            />
          )
        })}
      </Grid>
    </div>
  )
}
